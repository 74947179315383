import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string
}

const PageTitle = (props:Props) => {
  const { t } = useTranslation();
  return (
    <div className = "aider-exp-title" >{t(props.text)}</div>
  )
};

export default PageTitle;
