import React, { useState } from 'react';
import { Button, Form, Input, Modal, notification, Checkbox, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { addUser, addMember, addProfile } from 'src/api/api.ts';
import PasswordInput from 'src/components/PasswordInput/index.tsx';
import getEntitlementGroups from 'src/utils/getEntitlementGroups';
import { useAppSelector } from 'src/redux/hooks';

interface InputProps {
  onUserAdded: () => void;
  clientId: string;
}

const AddUser: React.FC<InputProps> = (props: InputProps) => {
  const [open, setOpen] = useState(false);
  const [loadingStage, setLoadingStage] = useState<string>('');
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const tenantId = useAppSelector(state => state.user.tenant.id);
  const isRoot = useAppSelector(state => state.user.isRoot);

  const handleSubmit = async () => {
    setLoadingStage(t('Adding user'));
    try {
      const values = await form.validateFields();
      const { email, password, confirm, firstName, lastName, isTenantAdmin } = values;

      // Try adding user to identity server
      try {
        let payload: API.AddUserPayload = {
          email: email,
          password: password,
          password2: confirm,
          first_name: firstName,
          last_name: lastName,
          tenant: tenantId,
        };

        await addUser(accessToken, payload);
      }
      catch (error: any) {
        if(error?.response?.status === 400){
          error?.response?.data?.email?.map((msg: string) =>
            form.setFields([{ name: 'email', errors: [`${t(msg)}`] }])
          );
          error?.response?.data?.password?.map((msg: string) =>
            form.setFields([{ name: 'password', errors: [`${t(msg)}`] }])
          );
        }
        else {
          notification['error']({
            message: t('Error'),
            description: t('User could not be added!'),
            placement: 'topRight',
            duration: 4.5,
          });
        }
        throw error;
      };

      // Try adding user's entitlements
    setLoadingStage(t('Setting user entitlements'));
      try {
        const { tenantAdmin } = getEntitlementGroups(tenantId)

        let groupPayload = {
          email: email,
          role: 'MEMBER',
        };

        if (isTenantAdmin) {
          await addMember(tenantAdmin, groupPayload, accessToken);
        }
        notification['success']({
          message: t('Success'),
          description: t('User has been added successfully!'),
          placement: 'topRight',
          duration: 4.5,
        });
        props.onUserAdded();
        setOpen(false);
      } catch (error: any) {
        notification['error']({
          message: t('Error'),
          description: t('User permissions could not be added!'),
          placement: 'topRight',
          duration: 4.5,
        });
        throw error;
      };

      // Try adding profile
    setLoadingStage(t('Creating profile'));
      try {
        let profilePayload: API.Profile = {
          first_name: firstName,
          last_name: lastName,
          user_id: email,
          active: true,
        }
        await addProfile(accessToken, profilePayload)
      } catch (error: any) {
          notification['warning']({
            message: t('Warning'),
            description: t('User has been created but profile could not be created, contact your administrator.'),
            placement: 'topRight',
            duration: 4.5,
          });
        throw error;
      };
      closeModal()
    }
    catch (error) {
      console.error(error)
    }
    setLoadingStage('');
  };

  const closeModal = () => {
    setOpen(false);
    form.resetFields();
  }

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        {t('Add User')}
      </Button>
      <Modal
        title={t('Add User')}
        open={open}
        onOk={handleSubmit}
        confirmLoading={loadingStage.length > 0}
        onCancel={() => closeModal()}
        destroyOnClose
      >
        <Spin spinning={loadingStage.length > 0} tip={`${loadingStage}...`}>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            label={t('Email')}
            name="email"
            required={true}
            rules={[{ required: true }, { type: 'email' }]}
          >
            <Input
              size="middle"
              maxLength={150}
              placeholder={t('Enter email here')}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label={t('First Name')}
            name="firstName"
            rules={[
              { required: true, message: t('Please enter the field!', { field: t('First Name').toLocaleLowerCase() }) },
              {
                pattern: new RegExp(/^[\p{L}øæåØÆÅ_ -]+$/u),
                message: t('First name should only contain letters, spaces, hyphens and underscores'),
              },
            ]}
          >
            <Input
              size="middle"
              maxLength={150}
              placeholder={t('Please input first name here.')}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label={t('Last Name')}
            name="lastName"
            required={true}
            rules={[
              { required: true, message: t('Please enter the field!', { field: t('Last Name').toLocaleLowerCase() }) },
              {
                pattern: new RegExp(/^[\p{L}øæåØÆÅ_ -]+$/u),
                message: t('Last name should only contain letters, spaces, hyphens and underscores'),
              },
            ]}
          >
            <Input
              size="middle"
              autoComplete="off"
              placeholder={t('Please input last name here.')}
            />
          </Form.Item>

          <PasswordInput form={form} popoverPlacement="topRight" />
          <Form.Item
            name="confirm"
            label={t('Confirm Password')}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  const error = t('The two passwords that you entered do not match!')
                  return Promise.reject(new Error(error));
                },
              }),
            ]}
          >
            <Input.Password
              size="middle"
              placeholder={t('Please input the password again.')}
              autoComplete="new-password"
            />
          </Form.Item>

          {isRoot == true && <Form.Item
            name="isTenantAdmin"
            valuePropName="checked"
          >
            <Checkbox>{t('Is tenant admin?')}</Checkbox>
          </Form.Item>}
        </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AddUser;