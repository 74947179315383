import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEntitlementGroups, getMyProfile } from 'src/api/api';
import dayjs from 'dayjs';
import decodeToken from 'src/utils/decodeToken';

export const setTokenData = createAsyncThunk(
  'user/fetchEntitlements',
  async (accessToken: string, { rejectWithValue }) => {
    try {
      const response = await getEntitlementGroups(accessToken);
      const groupIds: string[] = response.data.map((item: API.EntitlementGroup) => item.id);
      const decodedToken = decodeToken(accessToken);
      const returnData = {
        tenant: decodedToken.tenant,
        email: decodedToken.email,
        expiration: decodedToken.expiration,
        isRoot: decodedToken.rol.includes('root'),
        permissions: {
          groups: groupIds
        }
      }
      return returnData;
    } catch (error: any) {
      console.error('Failed to set user data', error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const setUserProfile = createAsyncThunk(
  'profile/fetchUserProfile',
  async (accessToken: string, { rejectWithValue }) => {
    try {
      const response = await getMyProfile(accessToken);
      return response.data;
    } catch (error: any) {
      console.error('Failed to set user profile', error);
      return rejectWithValue(error.response.data);
    }
  }
);

interface UserInfo {
  tenant: {
    id: string;
    name: string;
  };
  email: string;
  expiration: string;
  isRoot: boolean;
  permissions: {
    groups: string[];
    isTenantAdmin: boolean;
    isClientAdmin: boolean;
    isClientUser: boolean;
  };
  profile: API.ProfileMe
}

const initialState: UserInfo = {
  tenant: { id: '', name: '' },
  email: '',
  expiration: dayjs().toISOString(),
  isRoot: false,
  permissions: {
    groups: [],
    isTenantAdmin: false,
    isClientAdmin: false,
    isClientUser: false,
  },
  profile: {
    id: '',
    user_id: '',
    first_name: '',
    last_name: '',
    active: true,
    address: '',
    admins: [],
    users: [],
    bank_account: '',
    city: '',
    client_id: '',
    code: '',
    phone: 0,
    social_sec: '',
    uid: '',
    zip_code: '',
}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserInfo(state, action: PayloadAction<UserInfo>) {
      return { ...state, ...action.payload };
    },
    updateUserPermissions(state, action: PayloadAction<UserInfo["permissions"]>) {
      state.permissions = {...state.permissions, ...action.payload};
    },
    updateUserProfile(state, action: PayloadAction<API.ProfileMe>) {
      state.profile = {...state.profile, ...action.payload};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTokenData.fulfilled, (state, action) => {
      state.tenant = action.payload.tenant;
      state.email = action.payload.email;
      state.expiration = action.payload.expiration;
      state.isRoot = action.payload.isRoot;
      state.permissions.groups = action.payload.permissions.groups;
    });
    builder.addCase(setUserProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  },
});

export const { updateUserInfo, updateUserPermissions, updateUserProfile } = userSlice.actions;
export default userSlice.reducer;
export {initialState as userInitialState};
