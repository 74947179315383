import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.css';
import norwayFlag from 'src/assets/images/norway.svg';
import ukFlag from 'src/assets/images/unitedKingdom.svg';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { updateLanguage } from 'src/redux/slices/appSettingsSlice';

const languageConfigs = [
  {
    value: 'no',
    labelText: 'NO',
    imgSrc: norwayFlag,
    imgAlt: 'Norwegian Flag'
  },
  {
    value: 'en',
    labelText: 'EN',
    imgSrc: ukFlag,
    imgAlt: 'UK Flag'
  }
];

const LanguageSelector = () => {

  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const language = useAppSelector(state => state.appSettings.language)

  const languageOptions = languageConfigs.map(({ value, labelText, imgSrc, imgAlt }) => ({
    value,
    label: (
      <span className="aider-exp-lang-options">
        {labelText}&nbsp;<img src={imgSrc} alt={imgAlt} />
      </span>
    ),
  }));

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    dispatch(updateLanguage(value))
  }

  useEffect(() => {
    if (i18n.language !== language) {
      changeLanguage(language);
    }
  }, []);

  return (
    <Select
      onChange={(value) => changeLanguage(value)}
      value={i18n.language}
      options={languageOptions}
    />
  );
};

export default LanguageSelector;

