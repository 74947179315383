import React, { useCallback, useEffect, useState } from 'react';
import { Table, Button, Space, Switch, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { getDimensionCollection, updateDimension } from 'src/api/api';
import DepartmentModal from './departmentModal';
import { useAppSelector } from 'src/redux/hooks';

const Departments: React.FC = () => {
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);
  const [dimensions, setDimensions] = useState<API.Dimension[]>([]);
  const [loading, setLoading] = useState(false);
  const [updatingDimension, setUpdatingDimension] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dimensionToEdit, setDimensionToEdit] = useState<API.Dimension | null>(null);
  // Pagination state
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dimensionsSearch, setDimensionsSearch] = useState<API.FetchParams>({
    client_id: '',
    limit: 10,
    offset: 0,
  });

  const fetchDimensions = useCallback(async () => {
    if (!clientId || dimensionsSearch.client_id !== clientId) return;
    setLoading(true);
    try {
      const response = await getDimensionCollection(accessToken, dimensionsSearch);
      const dimensionsWithKey = response.data.items.map((item: API.Dimension) => ({
        ...item,
        key: item.id,
      }))
      setDimensions(dimensionsWithKey);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [accessToken, dimensionsSearch, clientId]);

  const openModal = (dimension?: API.Dimension) => {
    setDimensionToEdit(dimension || null);  // Null if creating a new dimension
    setIsModalOpen(true);
  };

  const switchDimensionStatus = async (record: API.Dimension) => {
    setUpdatingDimension(record.id!);
    try {
      await updateDimension(accessToken, { ...record, active: !record.active })
      const msg = record.active
        ? `${record.description} ${' '} ${t('is inactivated.')}`
        : `${record.description} ${' '} ${t('is activated.')}`;

      notification['success']({
        message: t('Success'),
        description: msg,
        placement: 'topRight',
        duration: 4.5,
      });
      setDimensions((prevDimensions) => {
        return prevDimensions.map((dim) => {
          if (dim.id === record.id) {
            return {
              ...dim,
              active: !record.active,
            };
          }
          return dim;
        });
      });
    }
    catch (error) {
      notification['error']({
        message: t('Error'),
        description: t('Could not change status of department'),
        placement: 'topRight',
        duration: 4.5,
      });
      console.error(error);
    }
    finally {
      setUpdatingDimension('');
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
    setDimensionToEdit(null);
  };

  // Combined useEffect to handle both clientId and fetching dimensions
  useEffect(() => {
    if (clientId && dimensionsSearch.client_id !== clientId) {
      // Only update dimensionsSearch if client_id has changed
      setDimensionsSearch((prev) => ({
        ...prev,
        client_id: clientId,
      }));
    } else if (clientId) {
      // Fetch data when clientId is available and dimensionsSearch is updated
      fetchDimensions();
    }
  }, [clientId, dimensionsSearch, fetchDimensions]);

  const handleTableChange = (page: number, pageSize: number) => {
    setDimensionsSearch((prev) => ({
      ...prev,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    }));
    setCurrentPage(page);
  };

  const columns = [
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('Active'),
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean, record: API.Dimension) => (
        <Switch
          key={record.id + 'isActive'}
          style={{ backgroundColor: active ? '#52c41a' : 'orange' }}
          checkedChildren={t('Activated')}
          unCheckedChildren={t('Inactivated')}
          value={record.active}
          loading={record.id === updatingDimension}
          onChange={() => switchDimensionStatus(record)}
        />
      ),
    },
    {
      title: t('Action'),
      key: 'action',
      render: (_: any, record: API.Dimension) => (
        <Button type="primary" onClick={() => openModal(record)}>
          {t('Edit')}
        </Button>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Button type="primary" onClick={() => openModal()}>
        {`${t('Add')} ${t('Department').toLowerCase()} `}
      </Button>
      <Table
        columns={columns}
        dataSource={dimensions}
        loading={loading}
        key={'id'}
        pagination={{
          current: currentPage,
          pageSize: dimensionsSearch.limit,
          total: totalItems,
          showSizeChanger: true,
          onChange: handleTableChange,
        }} />
      <DepartmentModal
        open={isModalOpen}
        onClose={handleModalClose}
        dimension={dimensionToEdit}
        onSubmit={fetchDimensions}  // Refetch dimensions on submit
      />
    </Space>
  );
};

export default Departments;
