import React, { useState } from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle/index.tsx';
import editProfile from 'src/assets/images/editProfile.jpg';
import './style.css'
//TO DO : Could be reading from file

const Help: React.FC = () => {
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState<string>('tab1');

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const GetStarted = () => {
    const { t } = useTranslation();

    return (
      <div>
        <h1>{t('Get started with Aider Expense')}</h1>
        <p>{t('Get Started Paragraph 1')}</p>

        <h2>{t('Get Started List 1 title')}</h2>
        <ul>
          <li>{t('Get Started List 1 item 1')}</li>
          <li>{t('Get Started List 1 item 2')}</li>
        </ul>

        <h3>{t('Get Started Subtitle 1')}</h3>
        <p>{t('Get Started Paragraph 2')}</p>
        <p>{t('Get Started Paragraph 3')}</p>
        <p>
          <a href="https://aiderexpensemini.com/" target="_blank" rel="noopener noreferrer">
            {t('Get Started Paragraph 4')}
          </a>
        </p>
        <p>{t('Get Started Paragraph 5')}</p>
        <p>{t('Get Started Paragraph 6')}</p>
        <p>{t('Get Started Paragraph 7')}</p>

        <h3>{t('Get Started Subtitle 2')}</h3>
        <p>{t('Get Started Paragraph 8')}</p>

        <ul>
          <li>
            <strong>{t('Get Started List 2 item 1')}</strong>
            <ul>
              <li>{t('Get Started List 2 item 1.1')}</li>
              <li>{t('Get Started List 2 item 1.2')}<br/><img style={{maxWidth: '700px'}} src={editProfile}/></li>
            </ul>
          </li>
          <li>
            <strong>{t('Get Started List 2 item 2')}</strong>
            <ul>
              <li>{t('Get Started List 2 item 2.1')}</li>
            </ul>
          </li>
        </ul>

        <p>{t('Get Started Paragraph 9')}</p>
        <h3>{t('Get Started Subtitle 3')}</h3>
        <p>{t('Get Started Paragraph 10')}</p>
      </div>
    );
  };

  const HowTo = () => {
    return (
      <div>
        <h1>{t('How to title 1')}</h1>
        <p>{t('How to paragraph 1')}</p>
        <h2>{t('How to list 1 title')}</h2>
        <ul>
          <li>{t('How to list 1 item 1')}</li>
          <li>{t('How to list 1 item 2')}</li>
        </ul>
        <h2>{t('How to list 2 title')}</h2>
        <p>{t('How to paragraph 2')}</p>
        <ul>
          <li>{t('How to list 2 item 1')}</li>
          <ul>
            <li>{t('How to list 2 item 1.1')}</li>
          </ul>
        </ul>
        <p>{t('How to paragraph 3')}</p>
        <ul>
          <li>{t('How to list 2 item 2')}</li>
          <ul>
            <li>{t('How to list 2 item 2.1')}</li>
          </ul>
        </ul>
        <p>{t('How to paragraph 4')}</p>
        <h2>{t('How to list 3 title')}</h2>
        <ul>
          <li>{t('How to list 3 item 1')}</li>
          <li>{t('How to list 3 item 2')}</li>
          <li>{t('How to list 3 item 3')}</li>
        </ul>
        <h2>{t('How to list 4 title')}</h2>
        <p>{t('How to paragraph 5')}</p>
        <h2>{t('How to list 5 title')}</h2>
        <p>{t('How to paragraph 6')}</p>
        <h2>{t('How to list 6 title')}</h2>
        <p>{t('How to paragraph 7')}</p>
        <h2>{t('How to list 7 title')}</h2>
        <p>{t('How to paragraph 8')}</p>
        <h2>{t('How to list 8 title')}</h2>
        <ul>
          <li>{t('How to list 8 item 1')}</li>
          <ul>
            <li>{t('How to list 8 item 1.1')}</li>
          </ul>
          <li>{t('How to list 8 item 2')}</li>
          <ul>
            <li>{t('How to list 8 item 2.1')}</li>
          </ul>
          <li>{t('How to list 8 item 3')}</li>
          <ul>
            <li>{t('How to list 8 item 3.1')}</li>
          </ul>
          <li>{t('How to list 8 item 4')}</li>
          <ul>
            <li>{t('How to list 8 item 4.1')}</li>
          </ul>
          <li>{t('How to list 8 item 5')}</li>
          <ul>
            <li>{t('How to list 8 item 5.1')}</li>
          </ul>
          <li>{t('How to list 8 item 6')}</li>
          <ul>
            <li>{t('How to list 8 item 6.1')}</li>
          </ul>
          <li>{t('How to list 8 item 7')}</li>
          <ul>
            <li>{t('How to list 8 item 7.1')}</li>
          </ul>
          <li>{t('How to list 8 item 8')}</li>
          <ul>
            <li>{t('How to list 8 item 8.1')}</li>
          </ul>
        </ul>
        <h2>{t('How to list 9 title')}</h2>
        <p>{t('How to paragraph 9')}</p>
        <h2>{t('How to list 10 title')}</h2>
        <p>{t('How to paragraph 10')}</p>
        <ul>
          <li>{t('How to list 10 item 1')}</li>
          <ul>
            <li>{t('How to list 10 item 1.1')}</li>
          </ul>
          <li>{t('How to list 10 item 2')}</li>
          <ul>
            <li>{t('How to list 10 item 2.1')}</li>
          </ul>
          <li>{t('How to list 10 item 3')}</li>
          <ul>
            <li>{t('How to list 10 item 3.1')}</li>
          </ul>
        </ul>
        <h2>{t('How to list 11 title')}</h2>
        <p>{t('How to paragraph 11')}</p>
        <h2>{t('How to list 12 title')}</h2>
        <p>{t('How to paragraph 12')}</p>
        <h2>{t('How to list 13 title')}</h2>
        <p>{t('How to paragraph 13')}</p>
      </div>
    );
  }

  const tabList = [
    {
      key: 'tab1',
      tab: t('Get started'),
    },
    {
      key: 'tab2',
      tab: t('How to use'),
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    tab1: GetStarted(),
    tab2: HowTo(),
  };

  return (
    <Card
      title={<PageTitle text='Help' />}
      style={{ width: '100%' }}
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      type="inner"
    >
      <div>{t('pages-help-card-text')}</div>
      {contentList[activeTabKey]}
    </Card>
  );
}
export default Help;