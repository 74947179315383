import { useEffect, useState } from 'react';

import { Table, Card, Space, Button, Popconfirm, notification, Typography } from 'antd';
import type { TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import GroupAddMember from 'src/components/AddGroupMember/index.tsx';
import { getMembers, removeGroupMember } from '../../../api/api.ts';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks.ts';

export default () => {
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const userEmail = useAppSelector(state => state.user.email);
  const [loading, setLoading] = useState(false);
  const [membersUpdated, setMembersUpdated] = useState(true);
  const [data, setData] = useState<API.GroupMember[]>([]);
  const [pagNum, setPagNum] = useState(0);
  const { t } = useTranslation();
  const { id: groupId } = useParams();

  const loadMembers = async () => {
    setLoading(true);
    try {
      const response = await getMembers(groupId!, accessToken);
      setData(response.data);
    } catch (error: any) {
      console.error(error);
      notification['error']({
        message: t('Error'),
        description: `${t('Failed to load members in group with')} ${groupId}`,
        placement: 'topRight',
        duration: 4.5,
      });
    } finally {
      setLoading(false);
    }
  };

  const onMembersUpdated = async () => {
    setMembersUpdated(true);
  };

  useEffect(() => {
    if (membersUpdated === true) {
      loadMembers();
      setMembersUpdated(false);
    }
  }, [membersUpdated]);

  const handleRemoveMember = async (row: API.GroupMember) => {
    try {
      await removeGroupMember(groupId!, row.id, accessToken);
      notification['success']({
        message: t('Success'),
        description: `${t('User')} ${row.id} ${t('has been removed from group')}  ${groupId}.`,
        placement: 'topRight',
        duration: 4.5,
      });

      setMembersUpdated(true);
    } catch (error) {

      notification['error']({
        message: t('Error'),
        description: `${t('Failed to remove group member')} ${row.id}`,
        placement: 'topRight',
        duration: 4.5,
      });
    }
  };

  const columns: TableProps<API.GroupMember>['columns'] = [
    {
      dataIndex: 'index',
      width: 48,
      key: 'index',
      className: 'tableIndex',
      fixed: 'left',
      render: (_, record, index) => {
        return {
          children: <span key={index + 1}>{index + 1 + 10 * pagNum}</span>
        };
      },
    },
    {
      title: t('Email'),
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => {
        return {
          children: record.id
        };
      },
    },
    {
      title: t('Role'),
      key: 'role',
      dataIndex: 'role',
      render: (text, record) => {
        return {
          children: record.role
        };
      },
    },
    {
      title: t('Type'),
      key: 'type',
      dataIndex: 'type',
      render: (text, record) => {
        return {
          children: record.type
        };
      },
    },
    {
      title: t('Action'),
      key: 'action',
      render: (text, record) => {
        return {
          children: <Popconfirm
            key={record.id}
            title={t('Confirmation')}
            description={`${t('User')} ${record.id} ${t('will be removed from group')} ${groupId}`}
            onConfirm={() => handleRemoveMember(record)}
            okText={'Ok'}
            cancelText={t('No')}
          >
            <Button
              type={'link'}
              disabled={
                record.id === userEmail
              }
            >
              {t('Remove')}
            </Button>
          </Popconfirm>
        };
      },
    },
  ];

  return (
    <Card
      title={<Typography.Title level={5}>{`${t('Members of')} ${groupId}`}</Typography.Title>}
    >
      <Space>
        <GroupAddMember
          groupId={groupId!}
          memberType="MEMBER"
          onMemberAdded={onMembersUpdated}
          members={data}
          disabled={false}
          buttonText={'Add Owner'}
        />
        <GroupAddMember
          groupId={groupId!}
          memberType="Member"
          onMemberAdded={onMembersUpdated}
          members={data}
          disabled={false}
          buttonText={'Add Member'}
        />
      </Space>
      <Table
        className="aex-table"
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
          onChange(page) {
            setPagNum(page - 1);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
        }}
      />
    </Card>
  );
};
