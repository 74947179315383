import { jwtDecode, JwtPayload } from "jwt-decode";
import dayjs from 'dayjs'
/**
 * @interface publicTokenData - extension of JwtPayload to include graspDP app values
 */
export interface publicTokenData extends JwtPayload {
    // Decoded token values
    cls: string;
    rol: string[];
    rsc: string;
    token_type: string;
    ver: string;
    // Front end created values from decoded token
    email: string;
    tenant: {
        id: string,
        name: string,
    };
    expiration: string;
}

const decodeToken = (token: string) => {
    const decodedToken: publicTokenData = jwtDecode(token);
    const emailParts = decodedToken.sub?.split('@')[0];
    const tenantInfo = decodedToken.rsc?.split(':');

    decodedToken.email = emailParts ?? '';
    decodedToken.tenant = {
        id: tenantInfo[0],
        name: tenantInfo[1],
    }
    decodedToken.expiration = dayjs(decodedToken?.exp! * 1000).toISOString();

    return decodedToken;
};
export default decodeToken;