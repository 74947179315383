import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultOptionType } from 'antd/es/select';
import { objectToOptions } from 'src/utils/objectToOptions';

interface ClientInfo {
  selectedClientData: API.ClientDetailed;
  selectedClientAdmins: API.GroupMember[];
  clientOptions: DefaultOptionType[];
  clientList: API.Client[];
  settingClient: boolean;
}

const initialState: ClientInfo = {
  selectedClientData: {
    address: '',
    admins: [],
    city: '',
    company_name: '',
    id: '', 
    org_nr: '',
    tenant_id: '',
    users: [], 
    zip_code: '',
    collect_settings: {
      internal_account: '',
      external_account_inbound: '',
      external_account_outbound: '',
      accounts_payable: '',
      id: '' 
    },
    expense_settings: {
      account_payable: '',
      multiple_rows: true,
      require_department: true,
      require_project: true,
      id: ''
    },
    engine_settings: {
      collect_guid: '',
      collect_file_path: '',
      collect_queue: '',
      expense_guid: '',
      expense_file_path: '',
      expense_queue: '',
      id: ''
    },
    target_system: ''
  },
  selectedClientAdmins: [],
  clientOptions: [],
  clientList: [],
  settingClient: false
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    updateClientInfo(state, action: PayloadAction<API.Client[]>) {
        state.clientOptions = objectToOptions(action.payload, 'company_name','id');
        state.clientList = action.payload;
    },
    updateSelectedClient(state, action: PayloadAction<API.ClientDetailed>) {
      state.selectedClientData = action.payload;
    },
    updateSelectedClientAdmins(state, action: PayloadAction<API.GroupMember[]>) {
      state.selectedClientAdmins = action.payload;
    },
    updateSettingClient(state, action: PayloadAction<boolean>) {
      state.settingClient = action.payload;
    }
  },
});

export const { updateSelectedClient, updateClientInfo, updateSettingClient, updateSelectedClientAdmins } = clientSlice.actions;
export default clientSlice.reducer;
export {initialState as clientInitialState};
