import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TenantState {
  tenantUsers: API.TenantUser[];
  tenantAdmins: API.GroupMember[],
}

const initialState: TenantState = {
  tenantUsers: [],
  tenantAdmins: [],
};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenantUsers(state, action: PayloadAction<API.TenantUser[]>) {
      state.tenantUsers = action.payload;
    },
    updateTenantAdmins(state, action: PayloadAction<API.GroupMember[]>) {
      state.tenantAdmins = action.payload;
    },
  },
});

export const { setTenantUsers, updateTenantAdmins } = tenantSlice.actions;
export default tenantSlice.reducer;
export {initialState as tenantInitialState};
