import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getVatCodes } from 'src/api/api';
import i18n from 'i18next';
import { DefaultOptionType } from 'antd/es/select';
import { objectToOptions } from 'src/utils/objectToOptions';

/**
 * Fetch VAT codes from the API or return them from Redux if they are not outdated.
 * @param {string} token - Authorization token for the API request.
 * @returns {Promise<API.VatCode[]>} - A promise that resolves to an array of VAT codes.
 */

export const setVatCodes = createAsyncThunk(
  'vatCodes/fetchVatCodes',
  async (params:{ accessToken: string, targetSystem: string}, { rejectWithValue }) => {
    try {
      const response = await getVatCodes(params.accessToken, params.targetSystem);
      const filteredCodes:API.VatCode[] = response.data.reduce((acc: API.VatCode[], vatCode: API.VatCode) => {
        if (!acc.find(code => code.code === vatCode.code)) {
          acc.push(vatCode);
        }
        return acc;
      }, []);
      addVatTranslationsToI18n(filteredCodes);
      return filteredCodes;
    } catch (error: any) {
      console.error('Failed to get vat codes', error);
      return rejectWithValue(error.filteredCodes);
    }
  }
);

interface VatCodesState {
  vatCodes: API.VatCode[];
  vatOptions: DefaultOptionType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: VatCodesState = {
  vatCodes: [],
  vatOptions: [],
  status: 'idle',
  error: null,
};

const vatCodesSlice = createSlice({
  name: 'vatCodes',
  initialState,
  reducers: {
    updateVatOptions(state, action: PayloadAction<API.VatCode[]>) {
      state.vatOptions = objectToOptions(action.payload, 'code', 'code', i18n.t);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setVatCodes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setVatCodes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vatCodes = action.payload;
        state.vatOptions = objectToOptions(action.payload, 'code', 'code', i18n.t);
      })
      .addCase(setVatCodes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch VAT codes';
      });
  },
});

/**
 * Adds VAT code translations to the i18n instance.
 * @param {API.VatCode[]} vatCodes - The VAT codes array.
 */
function addVatTranslationsToI18n(vatCodes: API.VatCode[]): void {
  const no: Record<string, string> = {};
  const en: Record<string, string> = {};

  vatCodes.forEach((vat) => {
    no[`option-${vat.code}`] = `${vat.code} - ${vat.description_NOB} | ${vat.tax_rate}`;
    en[`option-${vat.code}`] = `${vat.code} - ${vat.description_ENG} | ${vat.tax_rate}`;
  });

  Object.keys(no).forEach(key => {
    i18n.addResource('no', 'translation', key, no[key]);
  });

  Object.keys(en).forEach(key => {
    i18n.addResource('en', 'translation', key, en[key]);
  });
}

export const { updateVatOptions } = vatCodesSlice.actions;
export default vatCodesSlice.reducer;
export {initialState as vatCodesInitialState};
