import React, { useEffect, useState } from 'react';
import { Table, Button, Space, Switch, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { getProjectCollection, updateProject } from 'src/api/api';
import ProjectModal from './projectModal';
import { useAppSelector } from 'src/redux/hooks';

const Projects: React.FC = () => {
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);
  const [projects, setProjects] = useState<API.Project[]>([]);
  const [loading, setLoading] = useState(false);
  const [updatingProject, setUpdatingProject] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState<API.Project | null>(null);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const result = await getProjectCollection(accessToken, {client_id: clientId});
      setProjects(result.data.items);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchProjects();
  }, [accessToken, clientId]);

  const openModal = (project?: API.Project) => {
    setProjectToEdit(project || null);  // Null if creating a new project
    setIsModalOpen(true);
  };

  const switchProjectStatus = async (record: API.Project) => {
    setUpdatingProject(record.id!);
    try {
      await updateProject(accessToken, { ...record, active: !record.active })
      const msg = record.active
      ? `${record.description} ${' '} ${t('is inactivated.')}`
        : `${record.description} ${' '} ${t('is activated.')}`;

      notification['success']({
        message: t('Success'),
        description: msg,
        placement: 'topRight',
        duration: 4.5,
      });
      setProjects((prevProjects) => {
        return prevProjects.map((dim) => {
          if (dim.id === record.id) {
            return {
              ...dim,
              active: !record.active,
            };
          }
          return dim;
        });
      });
    }
    catch (error) {
      notification['error']({
        message: t('Error'),
        description: t('Could not change status of project'),
        placement: 'topRight',
        duration: 4.5,
      });
      console.error(error);
    }
    finally {
      setUpdatingProject('');
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
    setProjectToEdit(null);
  };

  const columns = [
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('Active'),
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean, record: API.Project) => (
        <Switch
          key={record.id + 'isActive'}
          style={{ backgroundColor: active ? '#52c41a' : 'orange' }}
          checkedChildren={t('Activated')}
          unCheckedChildren={t('Inactivated')}
          value={record.active}
          loading={record.id === updatingProject}
          onChange={() => switchProjectStatus(record)}
        />
      ),
    },
    {
      title: t('Action'),
      key: 'action',
      render: (_: any, record: API.Project) => (
        <Button type="primary" onClick={() => openModal(record)}>
          {t('Edit')}
        </Button>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Button type="primary" onClick={() => openModal()}>
        {`${t('Add')} ${t('Project').toLowerCase()} `}
      </Button>
      <Table columns={columns} dataSource={projects} loading={loading} rowKey="id"/>
      <ProjectModal
        open={isModalOpen}
        onClose={handleModalClose}
        project={projectToEdit}
        onSubmit={fetchProjects}  // Refetch projects on submit
      />
    </Space>
  );
};

export default Projects;
