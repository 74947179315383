import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootreducer';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

// Define persist configuration
const persistConfig = {
  key: 'root', // Key for storing data in local storage
  storage: sessionStorage,     // Type of storage (localStorage here)
  whitelist: ['appSettings','auth', 'user', 'client', 'tenant'], // Only persist certain reducers (e.g., 'auth')
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/REGISTER',
          'persist/FLUSH',
          'persist/PAUSE',
          'persist/PURGE',
        ],
        // Optionally ignore certain paths
        ignoredPaths: ['register', 'rehydrate'],
      },
      // Enable immutability checks only in development mode
      immutableCheck: process.env.STAGE !== 'prod',
    }),
});

// Persistor, used for persisting store data
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppStore = typeof store

export default store;