import React, { useEffect, useState } from 'react';
import { Form, Input, Table, Button, DatePicker, Upload, message, Select, Popconfirm, FormInstance, notification, Flex, Spin } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/es/upload';
import { DefaultOptionType } from 'antd/es/select';
import { type ExpenseFile } from './expense';
import { v4 as uuidv4 } from 'uuid';
import { downloadExpenseAttachment } from 'src/api/api';
import dayjs from 'dayjs';
import { validateInput } from 'src/utils/textUtils';
import { SyncOutlined } from '@ant-design/icons';
import { NumericFormat } from 'react-number-format';

interface ExpensesProps {
  token: string;
  status: string;
  headerId: string;
  form: FormInstance<any>;
  costTypes: DefaultOptionType[];
  files: ExpenseFile[];
  setFiles: React.Dispatch<React.SetStateAction<ExpenseFile[]>>;
  setFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
  onCostTypeSearch: (value: string) => void;
  costTypesLoading: boolean;
  maxMinDates: {
    maxDate: dayjs.Dayjs;
    minDate: dayjs.Dayjs;
  };
  approving: boolean;
  multipleRows: boolean;
}

const acceptedFileTypes = [
  'image/png',
  'image/jpeg',
  'image/heic',
  'application/pdf'
];

const Expenses: React.FC<ExpensesProps> = ({
  token,
  status,
  headerId,
  form,
  costTypes,
  files,
  setFiles,
  setFormChanged,
  onCostTypeSearch,
  costTypesLoading,
  maxMinDates,
  approving,
  multipleRows
}) => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const NumericFormatProps = {
    thousandSeparator: true,
    defaultValue: 0,
    decimalScale: 2,
    allowNegative: false,
    customInput: Input,
  }

  const formatNumber = (value: string): string => {
    // Convert the string to a number
    const numberValue = parseFloat(value.replace(/\s/g, '')); // remove any spaces if already present

    // Use Intl.NumberFormat for formatting
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    }).format(numberValue).replace(/,/g, ',');
  };

  const validateAmount = (value: string | number) => {
    const maxAmount = 100000; // Set your maximum value here
    
    // Check if the value is undefined or an empty string
    if (value === undefined || value === '') {
      return Promise.reject(new Error(t('Invalid amount!')));
    }

    // Remove non-numeric characters (except for decimal point)
    let amount = undefined;
    if (typeof value === 'number'){
      amount = value;
    }
    else {
      // Convert to float for comparison
      amount = parseFloat(value.replace(/[^0-9.-]+/g, ''));
    }

    // Check if the amount is less than or equal to zero
    if (amount <= 0) {
      return Promise.reject(new Error(t('Amount must be greater than zero')));
    }

    // Check if the amount exceeds the maximum value
    if (amount > maxAmount) {
      return Promise.reject(new Error(t('Amount must not exceed maxAmount', { maxAmount: formatNumber(maxAmount.toString()) })));
    }

    // If all checks pass, resolve the promise
    return Promise.resolve();
  }

  const amountFormatter = (value: string, index: number) => {
    // Set to 0 if the input is empty on blur
    if (value === '') {
      form.setFieldValue(['expenses', index, 'amount'], 0);
    }
    else {
      const numericValue = value.replace(/[^0-9.-]+/g, '');
      console.log(form.setFieldValue(['expenses', index, 'amount'], numericValue));
    }
  }

  const downloadAttachment = async (token: string, headerId: string, rowId: string) => {
    try {
      const response = await downloadExpenseAttachment(token, headerId, rowId);
      const contentType = response.headers['content-type'];
      const contentDisposition = response.headers['content-disposition'];

      let fileName = 'downloaded-file'; // Default filename
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      // Create a new Blob object using the response data and inferred MIME type
      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up
    }
    catch (error) {
      console.error(error);
    }
  }

  const changeFile = (id: string, index: number, file?: RcFile) => {
    if (file) {
      setFiles((prevFiles: ExpenseFile[]) =>
        prevFiles.map((expenseFile) =>
          expenseFile.id === id ? { ...expenseFile, file: file as File } : expenseFile
        )
      );
      return false; // Prevent the automatic upload
    }
    else {
      setFiles(() =>
        files.map((expenseFile) =>
          expenseFile.id === id ? { ...expenseFile, file: null, fileList: undefined } : expenseFile
        )
      );
      if (index) form.setFieldValue(['expenses', index, 'file_path'], '');
      return true;
    }
  };

  /**
 * When adding a row, a new uuid is created for it.
 * A new entry is created in the files array.
 */
  const addRow = () => {
    const expenses = form.getFieldValue('expenses') || [];
    let myuuid = uuidv4();

    form.setFieldsValue({
      expenses: [...expenses, {
        id: myuuid,
        date: maxMinDates.maxDate,
        file_path: '',
      }],
    });

    setFiles(
      [...files, {
        id: myuuid,
      }])
  };

  const removeFile = (id: string) => {
    setFiles(() =>
      files.filter((expenseFile) =>
        expenseFile.id !== id
      )
    );
  }

  const removeRow = (index: number, id: string) => {
    const expenses = form.getFieldValue('expenses') || [];
    form.setFieldsValue({
      expenses: expenses.filter((_: any, idx: number) => idx !== index),
    });
    removeFile(id);
    setFormChanged(true);
  };


  const columns = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '25%',
      render: (_: any, record: API.expenseRow, index: number) => (
        <Form.Item
          name={[index, 'title']}
          rules={[
            {
              required: true,
              message: t('Please enter the field!', { field: t('title').toLocaleLowerCase() }),
            },
            () => ({
              validator(_, value) {
                return validateInput(value, t);
              },
            }),
          ]}
          label={windowWidth < 641 ? t('Title') : null}
        >
          <Input
            autoComplete='off' />
        </Form.Item>
      ),
    },
    {
      title: t('Cost Type'),
      dataIndex: 'costType',
      key: 'costType',
      width: '25%',
      render: (_: any, record: API.expenseRow, index: number) => (
        <Form.Item
          name={[index, 'expense_account_id']}
          rules={[
            {
              required: true,
              message: t('Please enter the field!', { field: t('Cost Type').toLocaleLowerCase() }),
            }]}
          label={windowWidth < 641 ? t('Cost Type') : null}
        >
          <Select
            options={costTypes}
            allowClear={false}
            showSearch
            onSearch={onCostTypeSearch}
            filterOption={false}
            dropdownRender={menu => (
              <div>
                {costTypesLoading &&
                  <Flex align='center' justify='center' style={{ padding: 4 }}>
                    <Spin indicator={<SyncOutlined spin />} />
                    <span style={{ marginLeft: 8 }}>{t('Loading')}...</span>
                  </Flex>}
                {!costTypesLoading && menu}
              </div>
            )}
          />
        </Form.Item>
      ),
    },
    {
      title: t('Date'),
      dataIndex: 'rowDate',
      key: 'rowDate',
      width: '15%',
      render: (_: any, record: API.expenseRow, index: number) => (
        <Form.Item
          name={[index, 'date']}
          rules={[
            {
              required: true,
              message: t('Please enter the field!', { field: t('Date').toLocaleLowerCase() }),
            }]}
          label={windowWidth < 641 ? t('Date') : null}
        >
          <DatePicker
            format={['DD-MM-YYYY']}
            minDate={maxMinDates.minDate}
            maxDate={maxMinDates.maxDate}
          />
        </Form.Item>
      ),
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: '20%',
      render: (_: any, record: API.expenseRow, index: number) => (
        <Form.Item
          name={[index, 'amount']}
          rules={[
            {
              validator(_, value) {
                return validateAmount(value);
              },
            },
          ]}
          label={windowWidth < 641 ? t('Amount') : null}
        >
          <NumericFormat
            {...NumericFormatProps}
            onBlur={({ target }) => {
              amountFormatter(target.value, index);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: t('Attachments'),
      dataIndex: 'file_path',
      key: 'file_path',
      width: '10%',
      render: (_: any, record: API.expenseRow, index: number) => (
        <Form.Item
          name={['expenses', index, 'file_path']}
          rules={[
            () => ({
              validator(_, file) {
                console.log(!file || file?.file?.status === 'removed');
                if (approving && record.file_path === '' && (!file || file?.file.status === 'removed')) {
                  return Promise.reject(new Error(t('Please upload a file!')));
                }
                else {
                  return Promise.resolve();
                }
              },
            })]}>
          <Upload
            name="file"
            beforeUpload={(file) => {
              const isPNG = acceptedFileTypes.includes(file.type);
              if (!isPNG) {
                notification['error']({
                  message: t('File is not a supported file format', { fileName: file.name }),
                  description: t('Please upload a valid image (PNG, JPG, JPEG, GIF) or PDF'),
                  placement: 'topRight',
                  duration: 4.5,
                });
                return Upload.LIST_IGNORE; // Ignore invalid files
              }
              else {
                changeFile(record.id, index, file);
                return false;
              }
            }}
            onRemove={() => changeFile(record.id, index)}
            onPreview={() => downloadAttachment(token, headerId, record.id)}
            fileList={files[index]?.fileList}
            onChange={(info) => {
              if (info.file.status !== 'uploading') {
                console.log('uploading');
              }
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            multiple={false}
            maxCount={1}
            disabled={false}>
            <Button icon={<UploadOutlined />}>{t('Upload')}</Button>
          </Upload>
        </Form.Item>
      )
    },
    status !== "sent" ?
      {
        title: t('Action'),
        dataIndex: 'action',
        key: 'action',
        width: '5%',
        render: (_: any, record: API.expenseRow, index: number) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Popconfirm title={t('Sure to delete?')} onConfirm={() => removeRow(index, record.id)}>
              <CloseOutlined />
            </Popconfirm>
          </div>
        ),
      } : {},
  ];

  useEffect(() => {
    if (multipleRows) {
      if (!form.getFieldValue('expenses') && status === '') {
        addRow();
      }

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    else {
      const currentExpenses = form.getFieldValue('expenses') || [];

      // If there are no expenses in the form and status is empty, add a new one
      if (!currentExpenses.length && status === '') {
        const newExpenseId = uuidv4();

        // Set the form field 'expenses' to include the new expense
        form.setFieldsValue({
          expenses: [...currentExpenses, { id: newExpenseId }],
        });

        // Update the files state to track the new expense
        setFiles([...files, { id: newExpenseId }]);
      }
    }
  }, [])

  if (multipleRows) {
    return (
      <>
        <Button onClick={addRow} type="primary" style={{ marginBottom: 16 }} >
          {t('Add Row')}
        </Button>
        <Form.List name="expenses">
          {(fields) => (
            <>
              <Table
                dataSource={fields.map((field) => ({ ...field, ...form.getFieldValue(['expenses', field.key]) }))}
                columns={columns}
                rowKey={(record) => record.key ?? ''}
                pagination={false}
                size='small'
                className="aex-table"
                summary={() => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                              const expenses = getFieldValue('expenses') || [];
                              const totalAmount = expenses.reduce((sum: number, item: API.expenseRow) => {
                                const amount = Number(item.amount) || 0; // Convert amount to a number
                                return sum + amount; // Ensure sum is a number
                              }, 0).toFixed(2); // This is safe because sum is a number

                              return <pre>NOK {formatNumber(totalAmount)}</pre>;
                            }}
                          </Form.Item>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}
              />
            </>
          )}
        </Form.List>
      </>
    )
  }
  else {
    return (
      <div className='aex-single-expense'>
        <Form.Item
          name={['expenses', 0, 'expense_account_id']}
          rules={[{
            required: true,
            message: t('Please select the') + ' ' + t('Cost Type') + '!'
          }]}
          label={t('Cost Type')}
        >
          <Select
            options={costTypes}
            allowClear={false}
            showSearch
            onSearch={onCostTypeSearch}
            filterOption={false}
            dropdownRender={menu => (
              <div>
                {costTypesLoading &&
                  <Flex align='center' justify='center' style={{ padding: 4 }}>
                    <Spin indicator={<SyncOutlined spin />} />
                    <span style={{ marginLeft: 8 }}>{t('Loading')}...</span>
                  </Flex>}
                {!costTypesLoading && menu}
              </div>
            )}
          />
        </Form.Item>

        <Form.Item
          name={['expenses', 0, 'amount']}
          rules={[
            () => ({
              validator(_, value) {
                return validateAmount(value);
              },
            }),]}
          label={t('Amount')}
        >
          <NumericFormat
            {...NumericFormatProps}
            onBlur={({ target }) => {
              amountFormatter(target.value, 0);
            }}
          />
        </Form.Item>

        <Form.Item
          name={['expenses', 0, 'file_path']}
          rules={[
            () => ({
              validator(_, file) {
                if (approving && (!file || file?.file?.status === 'removed')) {
                  return Promise.reject(new Error(t('Please upload a file!')));
                }
                else {
                  return Promise.resolve();
                }
              },
            })]}>
          <Upload
            name="file"
            beforeUpload={(file) => {
              const isPNG = acceptedFileTypes.includes(file.type);
              if (!isPNG) {
                notification['error']({
                  message: t('File is not a supported file format', { fileName: file.name }),
                  description: t('Please upload a valid image (PNG, JPG, JPEG, GIF) or PDF'),
                  placement: 'topRight',
                  duration: 4.5,
                });
                return Upload.LIST_IGNORE; // Ignore invalid files
              }
              else {
                changeFile(files[0]?.id, 0, file); // Use the index to identify the correct expense in the array
                return false;
              }
            }}
            onRemove={() => changeFile(files[0]?.id, 0)} // Use the index to handle file removal
            onPreview={() => downloadAttachment(token, headerId, files[0]?.id)} // Use the correct id for preview
            fileList={files[0]?.fileList} // Correctly associate file list with the corresponding expense entry
            onChange={(info) => {
              if (info.file.status !== 'uploading') {
                console.log('uploading');
              }
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            multiple={false}
            maxCount={1}
            disabled={false}>
            <Button icon={<UploadOutlined />}>{t('Upload')}</Button>
          </Upload>
        </Form.Item>
      </div>
    )
  }
};

export default Expenses;