import React, { useState } from 'react';
import { List, Row, Col, Space, Flex, Tooltip, Descriptions, DescriptionsProps } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import EditProfile from './edit';
import { themeSettings } from 'src/configs/index.tsx';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { setUserProfile } from 'src/redux/slices/userSlice';

const MyInformation: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const userProfile = useAppSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);

  const items: DescriptionsProps['items'] = [
      {
        label: t('First Name'),
        children: userProfile.first_name,
        span: 3,
      },
      {
        label: t('Last Name'),
        children: userProfile.last_name,
        span: 3,
      },
      {
        label: t('Email'),
        children: userProfile.user_id,
        span: 3,
      },
      {
        label: t('Telephone'),
        children: userProfile.phone,
        span: 3,
      },
      {
        label: t('Address'),
        children: userProfile.address,
        span: 3,
      },
      {
        label: t('Zip Code'),
        children: userProfile.zip_code,
        span: 3,
      },
      {
        label: t('City'),
        children: userProfile.city,
        span: 3,
      },
      {
        label: t('Social sec'),
        children: userProfile.social_sec,
        span: 3,
      },
      {
        label: t('Bank Account'),
        children: userProfile.bank_account,
        span: 3,
      },
    ];

  const fetchMyProfileInfo = async () => {
    setLoading(true);
    await dispatch(setUserProfile(accessToken));
    setLoading(false);
  }

  return (
    <>
        <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={16}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Flex justify='space-between'>
              <EditProfile
                profile={userProfile}
              />
              <Tooltip title={t('Refresh')} color={themeSettings.token.colorPrimary} key="refresh-company-cards" >
                <RedoOutlined onClick={() => fetchMyProfileInfo()} />
              </Tooltip>
            </Flex>
            <Descriptions bordered items={items} />
          </Space>
        </Col>
    </>
  );
}
export default MyInformation;