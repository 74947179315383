import React from 'react';
import { Layout, ConfigProvider, Typography } from 'antd';
import enUSLocale from 'antd/locale/en_US';
import nbNOLocale from 'antd/locale/nb_NO';
import { useTranslation } from 'react-i18next';
import { themeSettings, AppRoutes, initLanguage, Navigation } from 'src/configs/index.tsx';
import './App.css';
import { Provider } from 'react-redux'
import {store, persistor} from './redux/store'
import AuthProvider from './components/AuthProvider/AuthProvider';
import { PersistGate } from 'redux-persist/integration/react';
const { Header, Content, Footer } = Layout;

initLanguage();

const App = () => {
  const { i18n, t } = useTranslation();

  /**
   * Norwegian locale is changed here because for its table component clear all text was not available
   */
  const customNbNOLocale = {
    ...nbNOLocale,
    Table: {
      ...nbNOLocale.Table,
      selectNone: 'Rydd alt', // Custom translation for pagination
    },
  };

  return (
    <ConfigProvider
      locale={i18n.language === 'en' ? enUSLocale : customNbNOLocale}
      theme={themeSettings}
    >
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
        <Layout className="app-layout" >
          <Header style={{ display: 'flex', alignItems: 'center' }} >
            <div className="aider-expense-logo">
              <Typography.Title level={2} className='app-logo' style={{ color: '#fff', margin: 0 }} >
                Aider Expense Mini
              </Typography.Title>
            </div>
            <Navigation />
          </Header>
          <Content className='expense-main-container' >
            <AppRoutes />
          </Content>
          <Footer className='app-footer'>
            Aider Expense mini ©{new Date().getFullYear()} {t('Created by Grasp Labs AS')}
          </Footer>
        </Layout>
        </AuthProvider>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
