import React, { useEffect, useMemo, useState } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import PageTitle from 'src/components/PageTitle/index.tsx';
import { useAppSelector } from 'src/redux/hooks';

const Settings: React.FC = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = useAppSelector(state => state.user.permissions);
  const [selectedTab, setSelectedTab] = useState<string>();

  const { isTenantAdmin, isClientAdmin } = permissions;
  const onChange = (key: string) => {
    setSelectedTab(key);
    navigate(key);
  };

  const items: TabsProps['items'] = [
    {
      key: 'my-information',
      label: t('My Information'),
    },
    ...(isTenantAdmin || isClientAdmin ? [
      {
        key: 'user-administration',
        label: t('User Administration'),
      },
      // {
      //   key: 'entitlements',
      //   label: t('Entitlements'),
      // },
      {
        key: 'client-access',
        label: t('Client Access'),
      },
      {
        key: 'company-cards',
        label: t('Manage Company Cards'),
      },
      {
        key: 'dimensions',
        label: t('Dimensions'),
      },
      {
        key: 'cost-types',
        label: t('Cost Types'),
      }
    ] : []),

  ];
  const selectedItem = useMemo(() => items.find(item => item.key === selectedTab), [items, selectedTab]);
  const pageTitle = selectedItem?.label?.toLocaleString() ?? '';

  useEffect(() => {
    const validPaths = new Set([
      'my-information',
      'user-administration',
      'entitlements',
      'company-cards',
      'client-access',
      'dimensions',
      'cost-types',
    ]);

    const splittedPath = location.pathname.split('/');
    const matchedTab = splittedPath.find(segment => validPaths.has(segment));
    if (matchedTab) {
      setSelectedTab(matchedTab);
    }
  }, [location.pathname]);

  return (
    <Card
      title={<PageTitle text={pageTitle} />}
      type="inner"
    >
      <Tabs
        defaultActiveKey="my-information"
        activeKey={selectedTab}
        items={items}
        onTabClick={onChange}
      />
      <Outlet />
    </Card>
  );
};

export default Settings;
