import React, { useCallback, useEffect, useState } from 'react';
import { Table, Card, Button, Typography, Space, Popconfirm, notification } from 'antd';
import type { TableProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'src/components/PageTitle/index.tsx';
import { deleteExpenseHeader, getExpensesList } from 'src/api/api.ts';
import { useAppSelector } from 'src/redux/hooks';

const MyExpenses: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);

  const [expenseList, setExpenseList] = useState<API.ExpensesListItem[]>([]);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [expensesSearch, setExpensesSearch] = useState<API.FetchParams>({
    limit: 10,
    offset: 0,
  });

  // Fetch expenses list with pagination
  const fetchExpenses = useCallback(async () => {
    if (!clientId || expensesSearch.client_id !== clientId) return; // Ensure clientId is set correctly
    setLoading(true);
    try {
      const response = await getExpensesList(accessToken, expensesSearch);
      const expensesWithKey = response.data.items.map((item: API.ExpensesListItem) => ({
        ...item,
        key: item.id,  // Add a unique key based on the id
      }));
      setExpenseList(expensesWithKey);
      setTotalItems(response.data.total);
    } catch (error: any) {
      console.error(error);
      notification['error']({
        message: t('Error'),
        description: t('Failed to load your expenses'),
        placement: 'topRight',
        duration: 4.5,
      });
    } finally {
      setLoading(false);
    };
  }, [accessToken, expensesSearch, clientId]);

  const deleteDraft = async (record: API.ExpensesListItem) => {
    setLoading(true);
    try {
      await deleteExpenseHeader(accessToken, record.id);
      fetchExpenses(); // Re-fetch the updated data
    } catch (error: any) {
      console.error('Error', error);
    }
  };

  // Combined useEffect to handle both clientId and fetching expenses
  useEffect(() => {
    if (clientId && expensesSearch.client_id !== clientId) {
      // Only update expensesSearch if client_id has changed
      setExpensesSearch((prev) => ({
        ...prev,
        client_id: clientId,
      }));
    } else if (clientId) {
      // Fetch data when clientId is available and expensesSearch is updated
      fetchExpenses();
    }
  }, [clientId, expensesSearch, fetchExpenses]);

  const handleTableChange = (page: number, pageSize: number) => {
    setExpensesSearch((prev) => ({
      ...prev,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    }));
    setCurrentPage(page);
  };

  const columns: TableProps<API.ExpensesListItem>['columns'] = [
    {
      title: t('Title'),
      dataIndex: 'purpose',
      key: 'purpose',
    },
    {
      title: t('Date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('Amount'),
      dataIndex: 'total',
      key: 'total',
      render: (number) => number.toFixed(2),
    },
    {
      title: t('Status'),
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => t(record.status),
    },
    {
      title: t('Type'),
      key: 'type',
      dataIndex: 'external',
      render: (_, record) => (record.external ? t('Private card') : t('Company card')),
    },
    {
      title: t('Actions'),
      key: 'action',
      render: (_, record) => (
        <Space key={`Space-${record.id}`}>
          <Button
            key={`edit-${record.id}`}
            type="primary"
            onClick={() => navigate('/new-expense', { state: { expenseData: record } })}
          >
            {record.status === 'draft' ? t('Edit') : t('Show')}
          </Button>
          {record.status === 'draft' && (
            <Popconfirm
              key={`delete-${record.id}`}
              title={t('Sure to delete?')}
              onConfirm={() => deleteDraft(record)}
            >
              <Button type="primary" danger>
                {t('Delete')}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card title={<PageTitle text="My Expenses" />} type="inner">
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Button type="primary" onClick={() => navigate('/new-expense')}>
          {t('New Expense')}
        </Button>
        <Table
          columns={columns}
          key={'id'}
          dataSource={expenseList}
          scroll={{ x: true }}
          pagination={{
            current: currentPage,
            pageSize: expensesSearch.limit,
            total: totalItems,
            showSizeChanger: true,
            onChange: handleTableChange,
          }}
          loading={loading}
          className="aex-table"
        />
      </Space>
    </Card>
  );
};

export default MyExpenses;
