/**
 * This function returns the name of the entitlement that represents the permission level of the user 
 *    1. Expense Tenant Admin
 *    2. Expense Client Admin
 *    3. Expense Client User
 * @param tenantId Tenant id
 * @param clientId Client id 
 * @returns a group
 */

const getEntitlementGroups = (tenantId: string, clientId: string = '') => {
     
      /**
       * User Administration Page -> Add User -> as tenant admin
       */
      const tenantAdmin = `user.expense.admin@default.${tenantId}`;
      // const tenantAdmin = [
      //       `service.expense.admin@default.${tenantId}`,
      //       `data.expense.admin@default.${tenantId}`,
      //       `users.admins@default.${tenantId}`,
      //       `service.entitlements.admin@default.${tenantId}`,
      // ];

      /**
        * User Administration Page -> Add User -> as normal tenant user
        */
      // const tenantUser = [
      //       `service.expense.user@default.${tenantId}`,
      //       `service.entitlements.user@default.${tenantId}`,
      // ];

      /**
       * Client Access Page : Create new admin for a selected client
       */
      const clientAdmin = `user.expense.admin@${clientId}.${tenantId}`
      // const clientAdmin = [
      //       `service.expense.admin@default.${tenantId}`,
      //       `data.expense.admin@${clientId}.${tenantId}`,
      //       `service.entitlements.admin@default.${tenantId}`,
      //       `users.viewers@default.${tenantId}`,
      // ];

      /**
       * Client Access Page : Assign new user to client
       */
      const clientUser = `user.expense.user@${clientId}.${tenantId}`;
      // const clientUser = [
      //       `data.expense.user@${clientId}.${tenantId}`,
      // ];

      return {
            tenantAdmin,
            // tenantUser,
            clientAdmin,
            clientUser,
      };
}
export default getEntitlementGroups;
