import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthInfo {
  accessToken: string;
  refreshToken: string;
}

const initialState: AuthInfo = {
  accessToken: '',
  refreshToken: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<AuthInfo>) {
      return { ...state, ...action.payload };
    },
    updateUserInfo(state, action: PayloadAction<AuthInfo>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { login, updateUserInfo } = authSlice.actions;
export default authSlice.reducer;
export { initialState as authInitialState }; // Export initialState
