import React, { useCallback, useEffect, useState } from 'react';
import { Table, Button, Space, Switch, notification, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCostTypes, updateCostType } from 'src/api/api';
import CostTypeModal from './costTypeModal';
import { useAppSelector } from 'src/redux/hooks';

const CostTypes: React.FC = () => {
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);

  const [costTypes, setCostTypes] = useState<API.CostType[]>([]);
  const [loading, setLoading] = useState(false);
  const [updatingCostType, setUpdatingCostType] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [costTypeToEdit, setCostTypeToEdit] = useState<API.CostType | null>(null);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchParams, setFetchParams] = useState<API.FetchParams>({
    client_id: clientId,
    limit: 10,
    offset: 0,
    order_type: 'ASC'
  });

  // Helper function to handle notifications
  const showNotification = useCallback(
    (type: 'success' | 'error', message: string, description: string) => {
      notification[type]({
        message: t(message),
        description: t(description),
        placement: 'topRight',
        duration: 4.5,
      });
    },
    [t]
  );

  // Fetch cost types
  const fetchCostTypes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getCostTypes(accessToken, fetchParams);
      const costTypesWithKey = response.data.items.map((item: API.ExpensesListItem) => ({
        ...item,
        key: item.id, // Add a unique key based on the id
      }));
      setCostTypes(costTypesWithKey);
      setTotalItems(response.data.total);
    } catch (error: any) {
      console.error('Error', error);
      showNotification('error', 'Error', 'Failed to load cost types');
    } finally {
      setLoading(false);
    }
  }, [accessToken, fetchParams, showNotification]);

  // Fetch cost types when component mounts or when accessToken/clientId changes
  useEffect(() => {
    if (accessToken && clientId) {
      fetchCostTypes();
    }
  }, [accessToken, clientId, fetchCostTypes]);

  // Open modal for editing or creating new cost type
  const openModal = (costType?: API.CostType) => {
    setCostTypeToEdit(costType || null); // Null if creating a new cost type
    setIsModalOpen(true);
  };

  // Switch cost type status (active/inactive)
  const switchCostTypeStatus = async (record: API.CostType) => {
    setUpdatingCostType(record.id!);
    try {
      await updateCostType(accessToken, record.id!, { ...record, active: !record.active });

      const successMessage = record.active
        ? `${record.description} ${t('is inactivated.')}`
        : `${record.description} ${t('is activated.')}`;

      showNotification('success', 'Success', successMessage);
      setCostTypes((prevCostTypes) =>
        prevCostTypes.map((item) =>
          item.id === record.id ? { ...item, active: !item.active } : item
        )
      );
    } catch (error) {
      console.error('Error switching cost type status:', error);
      showNotification('error', 'Error', 'Could not change status of department');
    } finally {
      setUpdatingCostType(null);
    }
  };

  // Handle modal close
  const handleModalClose = () => {
    setIsModalOpen(false);
    setCostTypeToEdit(null);
  };

  // Handle table pagination
  const handleTableChange = (page: number, pageSize: number) => {
    setFetchParams((prev) => ({
      ...prev,
      limit: pageSize,
      offset: (page - 1) * pageSize
    }));
    setCurrentPage(page);
  };

  // Define table columns
  const columns: TableProps<API.CostType>['columns'] = [
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('Account Number'),
      dataIndex: 'account_number',
      key: 'account_number',
    },
    {
      title: t('VAT code'),
      dataIndex: 'vat',
      key: 'vat',
    },
    {
      title: t('Active'),
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean, record: API.CostType) => (
        <Switch
          key={`${record.id}-isActive`}
          style={{ backgroundColor: active ? '#52c41a' : 'orange' }}
          checkedChildren={t('Activated')}
          unCheckedChildren={t('Inactivated')}
          checked={active}
          loading={record.id === updatingCostType}
          onChange={() => switchCostTypeStatus(record)}
        />
      ),
    },
    {
      title: t('Action'),
      key: 'action',
      render: (_: any, record: API.CostType) => (
        <Button type="primary" onClick={() => openModal(record)}>
          {t('Edit')}
        </Button>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Button type="primary" onClick={() => openModal()}>
        {`${t('Add')} ${t('Cost Type').toLowerCase()} `}
      </Button>
      <Table
        columns={columns}
        dataSource={costTypes}
        loading={loading}
        scroll={{ x: true }}
        pagination={{
          current: currentPage,
          pageSize: fetchParams.limit,
          total: totalItems,
          showSizeChanger: true,
          onChange: handleTableChange,
        }}
        rowKey="id"
      />
      <CostTypeModal
        open={isModalOpen}
        onClose={handleModalClose}
        costType={costTypeToEdit}
        onSubmit={fetchCostTypes} // Refetch costTypes on submit
      />
    </Space>
  );
};

export default CostTypes;