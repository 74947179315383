import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Card, message, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { userLogin } from '../../api/api.ts';
import type { CheckboxProps } from 'antd';
import { login } from 'src/redux/slices/authSlice.ts';
import { useAppDispatch } from 'src/redux/hooks.ts';
import { setTokenData } from 'src/redux/slices/userSlice.ts';
import maintImage from 'src/assets/images/maintenanceAider.jpg';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [credentialsError, setCredentialsError] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isRememberMeChanged, setIsRememberMeChanged] = useState(false);
  const [rememberedEmail] = useState<string | null>(localStorage.getItem('email'));
  const dispatch = useAppDispatch();

  //check if we have email field is localstorage : if its there means email is remembered
  const handleLogin = async (values: API.loginPayload) => {
    setLoading(true);
    try {
      const response = await userLogin(values);
      dispatch(login({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      }));
      dispatch(setTokenData(response.data.access_token));

      if (isRememberMeChanged) {
        if (values.remember) {
          localStorage.setItem('email', values.email);
        } else {
          localStorage.removeItem('email');
        }
      }
      navigate('/client');
    }
    catch (error: any) {
      console.error(error);
      if (error?.response?.status === 400) {
        if (error.response.data.email) {
          form.setFields([{ name: 'email', errors: error.response.data.email }])
        }
        if (error.response.data.non_field_errors) {
          setCredentialsError(error.response.data.non_field_errors);
        }
      } else {
        message.error(t('pages-login-index-error'));
      }
    }
    finally {
      setLoading(false);
    }

  };

  const onRememberMeChange: CheckboxProps['onChange'] = (e) => {
    setIsRememberMeChanged(true);
  }

  return (
    <Card
      className="aex-login-form"
      title={<span className='aider-exp-title aider-exp-login-title'>{t('Sign In')}</span>}
    >
      {
        credentialsError && <Alert message={credentialsError} type="error" showIcon className="login-error-alert" />
      }
      <Form
        initialValues={{
          email: rememberedEmail,
          remember: rememberedEmail !== null
        }}
        onFinish={handleLogin}
        autoComplete="off"
        form={form}
      >
        <Form.Item<API.loginPayload>
          label={t('Email')}
          name="email"
          rules={[{ required: true }, { type: 'email' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<API.loginPayload>
          label={t('Password')}
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item<API.loginPayload>
          name="remember"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox onChange={onRememberMeChange} >{t('Remember me')}</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('Sign In')}
          </Button>
          <Button type="link" onClick={() => navigate('/forgot-password')}>
            {t('Forgot Password')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default Login;
