import { refreshToken } from "src/api/api";
import decodeToken from "./decodeToken";
import { login } from "src/redux/slices/authSlice";
import { logout } from "src/redux/rootreducer";
import store from "src/redux/store";

export const tryRefreshingToken = async (refreshTokenFromState: string | null) => {
  if (refreshTokenFromState) {

    try {
      // Attempt to refresh the token
      const response = await refreshToken(refreshTokenFromState);

      const decodedToken = decodeToken(response?.data.access);
      if (!decodedToken) return null;

      // Dispatch login action with new tokens
      store.dispatch(login({
        refreshToken: refreshTokenFromState,
        accessToken: response?.data.access ?? '',
      }));

      // Return the response object to allow access to `data`
      return response;

    } catch (error) {
      // Handle error: logout user and navigate to login page
      store.dispatch(logout());
      window.location.href = '/login';
      console.error(error);
      return null; // Explicitly return null on failure
    }
  } else {
    // No refresh token, log out and navigate to login page
    store.dispatch(logout());
    window.location.href = '/login';
    return null; // Explicitly return null if no refresh token
  }
};
