import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setTokenData } from 'src/redux/slices/userSlice';
import { updateUserPermissions } from 'src/redux/slices/userSlice';
import { tryRefreshingToken } from 'src/utils/tokenUtils';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import dayjs from 'dayjs';
import getPermissions from 'src/utils/getPermissions';
import { setVatCodes, updateVatOptions } from 'src/redux/slices/vatCodesSlice';
import { setUserProfile } from 'src/redux/slices/userSlice';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const accessToken = useAppSelector(state => state.auth.accessToken);
  const refreshToken = useAppSelector(state => state.auth.refreshToken);
  const userInfo = useAppSelector(state => state.user);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);
  const clientTargetSystem = useAppSelector(state => state.client.selectedClientData.target_system);
  const vatCodes = useAppSelector(state => state.vatCodes.vatCodes);
  const settingClient = useAppSelector(state => state.client.settingClient)

  // Fetch entitlements when accessToken changes
  useEffect(() => {
    if (accessToken) {
      dispatch(setTokenData(accessToken));
      dispatch(setUserProfile(accessToken));
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    if(clientTargetSystem !== ''){
      dispatch(setVatCodes({accessToken: accessToken, targetSystem: clientTargetSystem}))
    }
  },[dispatch, accessToken, clientTargetSystem])

  useEffect(() => {
    dispatch(updateVatOptions(vatCodes));
  },[dispatch, vatCodes, t])

  // Token refresh interval
  useEffect(() => {
    if(userInfo?.tenant?.id !== ''){
      const tokenCheckInterval = setInterval(async () => {
        const expiry = userInfo.expiration;
        const isExpiringOrExpired = dayjs(expiry).diff(dayjs(), 'millisecond') < 600000; // Less than 10 minutes or already expired
        // Check token expiry
        if (expiry && isExpiringOrExpired) {
          await tryRefreshingToken(refreshToken);
        }
      }, 300000); // Check every 5 minutes
  
      return () => clearInterval(tokenCheckInterval);
    }
  }, [dispatch, navigate, userInfo?.expiration]);

  // Update permissions based on entitlements and clientId
  useEffect(() => {
    if (userInfo.permissions.groups.length > 0 && clientId) {
      const simplifiedPermissions = getPermissions(userInfo.permissions.groups, clientId, userInfo.tenant.id);
      dispatch(updateUserPermissions({
        groups: userInfo.permissions.groups,
        ...simplifiedPermissions,
      }));
    }
  }, [dispatch, clientId, userInfo?.permissions.groups, userInfo.tenant.id]);

  return (
    <Spin tip={t('Changing client')} spinning={settingClient ?? false}>
      {children}
    </Spin>
  );
};

export default AuthProvider;