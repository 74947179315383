import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface settings {
  language: string;
}

const initialState: settings = {
  language: 'no'
};

export const appSettings = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    updateLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
  },
});

export const { updateLanguage } = appSettings.actions;
export default appSettings.reducer;
export { initialState as appSettingsInitialState }; // Export initialState
