import React, { useEffect } from 'react';
import { Form, Checkbox, Input, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { addDimension, updateDimension } from 'src/api/api';
import { useAppSelector } from 'src/redux/hooks';

interface DepartmentModalProps {
  open: boolean;
  onClose: () => void;
  dimension: API.Dimension | null;
  onSubmit: () => void;
}

const DepartmentModal: React.FC<DepartmentModalProps> = ({ open, onClose, dimension, onSubmit }) => {
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientData = useAppSelector(state => state.client.selectedClientData);
  const [form] = Form.useForm();
  const isAdd = !dimension;

  useEffect(() => {
    if (dimension) {
      form.setFieldsValue({
        description: dimension.description,
        code: dimension.code,
        active: dimension.active,
      });
    }
    else {
      form.resetFields();
    }
  }, [dimension]);

  const handleFormSubmit = async (values: any) => {
    const data: API.Dimension = {
      ...dimension,
      client_id: clientData.id,
      description: values.description,
      code: values.code,
      active: values.active,
      admins: clientData.admins,
      users: clientData.users,
      uid: '', // (Sep 12) May be used in the future for external id mapping
    };
    try {
      if (isAdd) {
        await addDimension(accessToken, data);
      } else {
        await updateDimension(accessToken, data);
      }
      notification.success({
        message: t('Success'),
        description: `${isAdd ? t('Added') : t('Updated')} ${t('Department').toLocaleLowerCase()} ${t('successfully')}`,
        placement: 'topRight',
        duration: 4.5,
      });
      onClose();
      onSubmit();
    } catch (error: any) {
      console.error(error);
      if(error.response.status === 409){
        form.setFields([{ name: 'code', errors: [t('Code already in use')] }])
      }
      else{
        notification.error({
          message: t('Error'),
          description: `${isAdd ? t('Failed to add') : t('Failed to update')} ${t('Department').toLocaleLowerCase()}`,
          placement: 'topRight',
          duration: 4.5,
        });
      }
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();  // Validate the form
      handleFormSubmit(values);  // If valid, submit the form
    } catch (errorInfo) {
    }
  };

  return (
    <Modal
      title={`${isAdd ? t('Add') : t('Edit')} ${t('Department').toLocaleLowerCase()}`}
      open={open}
      onCancel={onClose}
      onOk={handleOk}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('Description')}
          name="description"
          rules={[{ required: true, message: t('Please enter the field!', {field: t('Description').toLocaleLowerCase()}) }]}
        >
          <Input maxLength={255} />
        </Form.Item>
        <Form.Item
          label={t('Code')}
          name="code"
          rules={[{ required: true, message: t('Please enter the field!', {field: t('Code').toLocaleLowerCase()}) }]}
        >
          <Input maxLength={20} />
        </Form.Item>
        <Form.Item name="active" valuePropName="checked" initialValue={true}>
          <Checkbox>{t('Is department active?')}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DepartmentModal;
