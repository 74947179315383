import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Input, Modal, notification, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { addCostType, getCostTypes, updateCostType } from 'src/api/api';
import { useAppSelector } from 'src/redux/hooks';
import { filterOptionsByLabel } from 'src/utils/textUtils';

interface CostTypeModalProps {
  open: boolean;
  onClose: () => void;
  costType: API.CostType | null;
  onSubmit: () => void;
}

const CostTypeModal: React.FC<CostTypeModalProps> = ({ open, onClose, costType, onSubmit }) => {
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientData = useAppSelector(state => state.client.selectedClientData);
  const [form] = Form.useForm();
  const isAddMode = !costType;
  const vatOptions = useAppSelector(state => state.vatCodes.vatOptions);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (costType) {
      form.setFieldsValue({
        description: costType.description,
        account_number: costType.account_number,
        vat: costType.vat,
        active: costType.active,
      });
    } else {
      form.resetFields();
    }
  }, [costType, form]);

  const handleFormSubmit = async (values: any) => {
    setSubmitting(true);
    const data: API.CostType = {
      ...costType,
      client_id: clientData.id,
      description: values.description,
      account_number: values.account_number,
      vat: values.vat,
      active: values.active,
      admins: clientData.admins,
      users: clientData.users,
    };

    try {
        if (isAddMode) {
          await addCostType(accessToken, data);
        } else {
          await updateCostType(accessToken, costType!.id!, data);
        }

        notification.success({
          message: t('Success'),
          description: `${t(isAddMode ? 'Added' : 'Updated')} ${t('Cost Type')} ${t('successfully')}`,
          placement: 'topRight',
          duration: 4.5,
        });

        onSubmit();
        onClose();
      }
      catch (error: any) {
        console.error(error);
      notification.error({
        message: t('Error'),
        description: `${t(isAddMode ? 'Failed to add' : 'Failed to update')} ${t('Cost Type')}, ${t('please try again.')}`,
        placement: 'topRight',
        duration: 4.5,
      });
    }
    finally {
      setSubmitting(false);
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields(); // Validate the form before submission
      handleFormSubmit(values); // Submit the form if valid
    } catch (errorInfo) {
      // Form validation error, handled by antd
    }
  };

  return (
    <Modal
      title={`${t(isAddMode ? 'Add' : 'Edit')} ${t('Cost Type')}`}
      open={open}
      onCancel={onClose}
      onOk={handleOk}
      destroyOnClose
    >
      <Spin 
      spinning={submitting}
      >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('Description')}
          name="description"
          rules={[{ required: true, message: t('Please enter the field', { field: t('Description').toLocaleLowerCase() }) }]}
        >
          <Input 
            size="middle"
            maxLength={255}
          />
        </Form.Item>

        <Form.Item
          label={t('Account Code in ERP')}
          name="account_number"
          rules={[{ required: true, message: t('Please enter the field', { field: t('Account number').toLocaleLowerCase() }) }]}
        >
          <Input 
            size="middle"
            maxLength={20}
          />
        </Form.Item>

        <Form.Item
          label={t('VAT code')}
          name="vat"
          rules={[{ required: true, message: t('Please enter the field', { field: t('VAT code').toLocaleLowerCase() }) }]}
        >
          <Select
            showSearch
            options={vatOptions}
            filterOption={(input, option) =>
              filterOptionsByLabel(input, option)
            }
            optionFilterProp="label"
          />
        </Form.Item>

        <Form.Item name="active" valuePropName="checked" initialValue={true}>
          <Checkbox>{t('Is cost type active?')}</Checkbox>
        </Form.Item>
      </Form>
      </Spin>
    </Modal>
  );
};

export default CostTypeModal;
