import { combineReducers } from '@reduxjs/toolkit';
import userReducer, { userInitialState } from './slices/userSlice';
import clientReducer, { clientInitialState } from './slices/clientSlice';
import tenantReducer, { tenantInitialState } from './slices/tenantSlice';
import authReducer, { authInitialState } from './slices/authSlice';
import vatCodesReducer, { vatCodesInitialState } from './slices/vatCodesSlice';
import appSettingsReducer, { appSettingsInitialState } from './slices/appSettingsSlice';

export const logout = () => ({
  type: 'LOGOUT',
});

const appReducer = combineReducers({
  user: userReducer,
  client: clientReducer,
  tenant: tenantReducer,
  auth: authReducer,
  vatCodes: vatCodesReducer,
  appSettings: appSettingsReducer,
});

const getInitialState = () => ({
  auth: authInitialState,
  user: userInitialState,
  client: clientInitialState,
  tenant: tenantInitialState,
  vatCodes: vatCodesInitialState,
  appSettings: appSettingsInitialState,
});

// Root reducer handling global logout
const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
  if (action.type === 'LOGOUT') {
    return {
      ...getInitialState(),
      appSettings: state?.appSettings, // Preserve the user state on logout
    };
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
