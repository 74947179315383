import React, { useState, useEffect, useCallback } from 'react';
import { Table, Space, Flex, Tooltip, notification, Button, Tag, Switch } from 'antd';
import type { TableProps } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getCompanyCardCollection, updateCompanyCard } from '../../../api/api.ts';
import { themeSettings } from 'src/configs/index.tsx';
import { useAppSelector } from 'src/redux/hooks.ts';
import CardModal from './cardModal.tsx';

const CompanyCardsList: React.FC = () => {
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);

  const [loadingCards, setLoadingCards] = useState(false);
  const [updatingCards, setUpdatingCards] = useState<string[]>([]);
  const [cardToEdit, setCardToEdit] = useState<API.CompanyCard | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [cardsList, setCardsList] = useState<API.CompanyCard[]>([]);
  const [newCardAdded, setNewCardAdded] = useState(false);
  // Pagination state
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyCardSearch, setcompanyCardSearch] = useState<API.FetchParams>({
    client_id: '',
    limit: 10,
    offset: 0,
  });

  const fetchCompanyCards = useCallback(async () => {
    if (!clientId || companyCardSearch.client_id !== clientId) return;
    setLoadingCards(true);
    try {
      const response = await getCompanyCardCollection(accessToken, companyCardSearch)
      setCardsList(response.data.items);
      setTotalItems(response.data.total);
    }
    catch (error) {
      console.error(error);
      notification['error']({
        message: t('Error'),
        description: t('Sorry, Failed to get company cards.'),
        placement: 'topRight',
        duration: 4.5,
      });
    };
    setLoadingCards(false);
  }, [accessToken, companyCardSearch, clientId]);

  const switchCardStatus = async (record: API.CompanyCard) => {
    setUpdatingCards(prev => [...prev, record.id]);
    try {
      await updateCompanyCard(accessToken, { ...record, active: !record.active }, record.id)
      const msg = record.active
        ? `${record.card_name} ${' '} ${t('is inactivated.')}`
        : `${record.card_name} ${' '} ${t('is activated.')}`;

      notification['success']({
        message: t('Success'),
        description: msg,
        placement: 'topRight',
        duration: 4.5,
      });
      setCardsList((prev) => {
        return prev.map((dim) => {
          if (dim.id === record.id) {
            return {
              ...dim,
              active: !record.active,
            };
          }
          return dim;
        });
      });
    }
    catch (error) {
      notification['error']({
        message: t('Error'),
        description: t('Could not change status of department'),
        placement: 'topRight',
        duration: 4.5,
      });
      console.error(error);
    }
    finally {
      setUpdatingCards(prev => prev.filter(x => x !== record.id));
    }
  }

  useEffect(() => {
    fetchCompanyCards();
  }, [clientId, accessToken]);

  useEffect(() => {
    if (newCardAdded) {
      fetchCompanyCards();
      setNewCardAdded(prevState => !prevState);
    }
  }, [newCardAdded]);

  const openModal = (card?: API.CompanyCard) => {
    setCardToEdit(card || null);  // Null if creating a new dimension
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setCardToEdit(null);
    setIsModalOpen(false);
  }

  // Combined useEffect to handle both clientId and fetching dimensions
  useEffect(() => {
    if (clientId && companyCardSearch.client_id !== clientId) {
      // Only update dimensionsSearch if client_id has changed
      setcompanyCardSearch((prev) => ({
        ...prev,
        client_id: clientId,
      }));
    } else if (clientId) {
      // Fetch data when clientId is available and dimensionsSearch is updated
      fetchCompanyCards();
    }
  }, [clientId, companyCardSearch, fetchCompanyCards]);

  const handleTableChange = (page: number, pageSize: number) => {
    setcompanyCardSearch((prev) => ({
      ...prev,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    }));
    setCurrentPage(page);
  };

  const columns: TableProps<API.CompanyCard>['columns'] = [
    {
      title: t('Card Name'),
      dataIndex: 'card_name',
      key: 'card_name'
    },
    {
      title: t('Account Number'),
      dataIndex: 'account_number',
      key: 'account_number'
    },
    {
      title: t('Status'),
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean, record: API.CompanyCard) => (
        <Switch
          key={record.id + 'isActive'}
          style={{ backgroundColor: active ? '#52c41a' : 'orange' }}
          checkedChildren={t('Activated')}
          unCheckedChildren={t('Inactivated')}
          value={record.active}
          loading={updatingCards.includes(record.id)}
          onChange={() => switchCardStatus(record)}
        />
      ),
    },
    {
      title: t('Action'),
      key: 'action',
      render: (_, record) => {
        return {
          children: <Button type='primary' onClick={() => openModal(record)}>{t('Edit')}</Button>,
        };
      },
    },
  ];

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

        <Flex justify='space-between'>
          <Button type='primary' onClick={() => openModal()}>{t('Add Card')}</Button>
          <Tooltip title={t('Refresh')} color={themeSettings.token.colorPrimary} key="refresh-company-cards" >
            <RedoOutlined onClick={() => fetchCompanyCards()} />
          </Tooltip>
        </Flex>

        <Table
          rowKey={(record) => record.id}
          loading={loadingCards}
          columns={columns}
          dataSource={cardsList}
          scroll={{ x: true }}
          pagination={{
            current: currentPage,
            pageSize: companyCardSearch.limit,
            total: totalItems,
            showSizeChanger: true,
            onChange: handleTableChange,
          }}
          className="aex-table"
          expandable={{
            expandedRowRender: (record) =>
              <div>
                {t('Users')}
                <ul>
                  {record.users.filter(item => !item.includes('data.expense.admin')).map(item =>
                    <li key={item}>
                      <span>{item}</span>
                    </li>
                  )}
                </ul>
              </div>,
          }}
        />
      </Space>
      <CardModal
        open={isModalOpen}
        onSubmit={fetchCompanyCards}
        onClose={closeModal}
        companyCard={cardToEdit}
      />
    </>
  );
}
export default CompanyCardsList;