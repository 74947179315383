import React, { useState, useEffect, useMemo } from 'react';
import { Space, Form, Input, Select, Card, Button, DatePicker, Flex, Switch, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PageTitle from '../../components/PageTitle/index.tsx';
import { debounce } from 'lodash';
import { SyncOutlined } from '@ant-design/icons';

import {
  createExpenseHeader,
  getExpenseHeader,
  sendExpenseHeader,
  getDimensionCollection,
  getProjectCollection,
  getCompanyCardCollection,
  getCostTypes,
  uploadExpenseAttachment,
  updateExpenseHeader,
  updateExpenseRows,
  getDimension,
  getProject,
  getCostType,
  getCompanyCard
} from '../../api/api.ts';
import { AxiosResponse } from 'axios';
import { DefaultOptionType } from 'antd/es/select/index';
import type { UploadFile } from 'antd';
import { cleanInput, validateInput } from 'src/utils/textUtils.ts';
import { useAppSelector } from 'src/redux/hooks.ts';
import Expenses from './expenses.tsx';

dayjs.extend(customParseFormat);

export interface ExpenseRowItem {
  title: string,
  costType: string,
  date: string,
  amount: string,
  expense_account_id: string;
  id?: string,
  file_path?: string,
}

/**
 * @interface ExpenseFile - allows relation of files and expense rows
 * The logic behind this interface is that when a new row is created, a UUID is assigned to that row.
 * That UUID belongs to ths ExpenseFile interface as well.
 * A new expense file has a file but no fileList, an expense file from api has a fileList but no file,
 * an update will have both a file and a filelist. Deletion is handled at row removal.
 */
export interface ExpenseFile {
  id: string,
  file?: File | null,
  fileList?: UploadFile[],
}

const Expense: React.FC = () => {
  const location = useLocation();
  const recordToEdit: API.expenseHeaderParams = location.state?.expenseData;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);
  const profile = useAppSelector(state => state.user.profile);
  const expenseSettings = useAppSelector(state => state.client.selectedClientData.expense_settings);
  const [form] = Form.useForm();
  const [status, setStatus] = useState<string>(recordToEdit?.status ?? "");
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [loadingExpense, setLoadingExpense] = useState(false);
  const [headerId, setHeaderId] = useState(recordToEdit?.id ?? '');
  const [isPrivateCard, setIsPrivateCard] = useState<boolean>((recordToEdit?.external ?? true) && profile.bank_account !== '');
  const [projects, setProjects] = useState();
  const [projectsLoading, setProjectsLoading] = useState(false);
  const initialFetch: API.FetchParams = {
    client_id: clientId,
    offset: 0,
    limit: 10,
    search: '',
  }

  const [fetchProjectsParams, setFetchProjectsParams] = useState<API.FetchParams>(initialFetch);
  const [dimensions, setDimensions] = useState<DefaultOptionType[]>([]);
  const [dimensionsLoading, setDimensionsLoading] = useState(false);
  const [fetchDimensionsParams, setFetchDimensionsParams] = useState<API.FetchParams>(initialFetch);
  const [costTypes, setCostTypes] = useState<DefaultOptionType[]>([]);
  const [costTypesLoading, setCostTypesLoading] = useState(false);
  const [fetchCostTypesParams, setFetchCostTypesParams] = useState<API.FetchParams>(initialFetch);
  const [companyCards, setCompanyCards] = useState<DefaultOptionType[]>([]);
  const [companyCardsLoading, setCompanyCardsLoading] = useState(false);
  const [fetchCompanyCardsParams, setFetchCompanyCardsParams] = useState<API.FetchParams>(initialFetch);
  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState<ExpenseFile[]>([]);
  const [expensesLoading, setExpensesLoading] = useState(false);
  const [approving, setApproving] = useState(false);
  const maxMinDates = { maxDate: dayjs(), minDate: dayjs().startOf('year') };

  //Upload file per row, needs reengineered
  const handleUpload = async (createResponse: AxiosResponse<any, any>) => {
    const expenses = form.getFieldValue('expenses') || [];
    const rowsReturned = createResponse.data.expense_rows;
    const headerId = createResponse.data.id;
    const uploadPromises = expenses.map(async (expense: { attachment: any; }, index: number) => {
      const file = expense.attachment;
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        try {
          await uploadExpenseAttachment(accessToken, headerId, rowsReturned[index].id, files[index]);
        } catch (error) {
          console.error('Upload failed', error);
        }
      }
    });

    await Promise.all(uploadPromises);
  }

  //creates Expenses on new expense save 
  const createExpense = async () => {
    setFormChanged(false);
    try {
      setSubmitting(true);
      await form.validateFields();
      const expenseHeaderDate = dayjs(form.getFieldValue('date')).format('YYYY-MM-DD');
      const expensesPayload = form.getFieldValue('expenses').map((item: ExpenseRowItem) => ({
        ...item,
        //If its single row assign expense header purpose as title and header date as date, only one row should exist.
        title: form.getFieldValue('purpose'),
        date: expenseSettings.multiple_rows ? dayjs(item.date).format('YYYY-MM-DD') : expenseHeaderDate
      }));
      const dataToSend: API.expenseHeaderParams = {
        status: 'draft',
        date: expenseHeaderDate,
        purpose: form.getFieldValue('purpose'),
        external: isPrivateCard,
        dimension_id: form.getFieldValue('dimension'),
        project_id: form.getFieldValue('project'),
        expense_rows: expensesPayload,
        client_id: clientId,
        company_card_id: form.getFieldValue('card'),
        profile_id: isPrivateCard ? profile.id : null
      }
      await createExpenseHeader(accessToken, dataToSend)
        .then(async response => {
          setHeaderId(response.data.id);
          await handleUpload(response);
          const returnedExpenseRows = response.data.expense_rows.map((item: ExpenseRowItem) => ({
            ...item,
            id: item.id,
            date: dayjs(item.date),
          }));
          form.setFieldValue('expenses', returnedExpenseRows);
          uploadFiles(response.data.id);
        });
      setStatus("draft");
      message.success(t('Expense created!'));
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setSubmitting(false);
      setFormChanged(false);
    }
  };

  // update expense form 
  const updateExpense = async () => {
    setFormChanged(false);
    setSubmitting(true);
    try {
      await form.validateFields();
      const dataToSend: API.expenseHeaderParams = {
        status: 'draft',
        date: dayjs(form.getFieldValue('date')).format('YYYY-MM-DD'),
        purpose: form.getFieldValue('purpose'),
        external: isPrivateCard,
        dimension_id: form.getFieldValue('dimension'),
        project_id: form.getFieldValue('project'),
        client_id: clientId,
        company_card_id: form.getFieldValue('card'),
        admins: recordToEdit.admins,
        users: recordToEdit.users,
        profile_id: recordToEdit.profile_id //profile id should be of the original expense creator
      }
      //save header
      updateExpenseHeader(accessToken, headerId, dataToSend);
      //save rows
      const expensesPayload = form.getFieldValue('expenses').map((item: ExpenseRowItem) => ({
        ...item,
        date: dayjs(item.date).format('YYYY-MM-DD')
      }));
      await updateExpenseRows(accessToken, headerId, expensesPayload);
      //save files
      uploadFiles();
      message.success(t('Expense saved!'));
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setSubmitting(false);
      setFormChanged(false);
    }
  }

  //Uploads each expense row file
  const uploadFiles = (id?: string) => {
    files.forEach(file => {
      if (file.file) {
        uploadExpenseAttachment(accessToken, id ?? headerId, file.id, file.file);
      }
    })
  }

  // approve expense form
  const approveExpense = async () => {
    setApproving(true);
    form.validateFields();
    if (headerId !== '') {
      const expenses = form.getFieldValue('expenses') || [];

      // Check if all rows have attachments
      const allRowsHaveAttachments = expenses.every((expense: ExpenseRowItem) => {
        const correspondingFile = files.find(file => file.id === expense.id);
        return correspondingFile && (correspondingFile.file || (correspondingFile.fileList && correspondingFile.fileList.length > 0));
      });

      if (!allRowsHaveAttachments) {
        message.error(t('Please attach files to all expense rows before submitting.'));
        return;
      }

      setSubmitting(true);
      try {
        await sendExpenseHeader(accessToken, headerId);
        message.success(t('Expense aproved!'));
        navigate('/my-expenses');
        setStatus("sent");
      }
      catch (error) {
        console.error(error);
      }
      finally {
        setSubmitting(false);
        setApproving(false);
      }
    }
  };

  //for private card toggle change
  const onPrivateCardChange = (checked: boolean) => {
    setIsPrivateCard(checked);
  };

  /* Start of Fetch Dimensions Block */

  //Fetches dimension collection and sets dimensions as dropdown options
  const fetchDimensionCollection = async () => {
    setDimensionsLoading(true);
    try {
      const collectionResponse = await getDimensionCollection(accessToken, fetchDimensionsParams);
      const filteredResponse = collectionResponse.data.items.filter((x: API.Dimension) => x.active || x.id === recordToEdit?.dimension_id)
      setDimensions(filteredResponse.map((item: any) => ({
        label: item.description,
        value: item.id,
        disabled: !item.active
      })));
    } catch (error) {
      console.error('Error', error);
    }
    finally {
      setDimensionsLoading(false);
    };
  }

  //Fetches single dimension for sent expenses
  const fetchDimension = async () => {
    setDimensionsLoading(true);
    try {
      const dimensionResponse = await getDimension(accessToken, recordToEdit.dimension_id);
      setDimensions(prev => [...prev, {
        label: dimensionResponse.data.description,
        value: dimensionResponse.data.id,
        disabled: !dimensionResponse.data.active
      }])
    } catch (error) {
      console.error('Error', error);
    }
    finally {
      setDimensionsLoading(false);
    };
  }

  // Memoized debounce handler
  const debouncedFetchDimensions = useMemo(() => debounce((value) => {
    setFetchDimensionsParams((prev) => ({
      ...prev,
      search: value,
      limit: value === '' ? 10 : 200,  // reset offset on search
    }));
  }, 500), []);

  // Effect to trigger fetch when search parameter changes
  useEffect(() => {
    fetchDimensionCollection();
  }, [fetchDimensionsParams]);

  /* End of Fetch Dimensions Block */
  /* Start of Fetch Projects Block */
  const fetchProjectCollection = async () => {
    setProjectsLoading(true)
    try {
      const response = await getProjectCollection(accessToken, fetchProjectsParams);
      const filteredResponse = response.data.items.filter((x: API.Project) => x.active || x.id === recordToEdit?.project_id);
      setProjects(filteredResponse.map((item: API.Project) => ({
        label: item.description,
        value: item.id,
        disabled: !item.active
      })));
    }
    catch (error) {
      console.error('Error', error);
    }
    finally {
      setProjectsLoading(false);
    };
  }

  //Fetches single project for sent expenses
  const fetchProject = async () => {
    setProjectsLoading(true);
    try {
      const response = await getProject(accessToken, recordToEdit.project_id);
      setDimensions(prev => [...prev, {
        label: response.data.description,
        value: response.data.id,
        disabled: !response.data.active
      }])
    } catch (error) {
      console.error('Error', error);
    }
    finally {
      setDimensionsLoading(false);
    };
  }

  // Memoized debounce handler
  const debouncedFetchProjects = useMemo(() => debounce((value) => {
    setFetchProjectsParams((prev) => ({
      ...prev,
      search: value,
      limit: value === '' ? 10 : 200,  // reset offset on search
    }));
  }, 500), []);

  // Effect to trigger fetch when search parameter changes
  useEffect(() => {
    fetchProjectCollection();
  }, [fetchProjectsParams]);
  /* End of Fetch Projects Block */

  /* Start of Fetch Cost Types (Expense Accounts) Block */
  const fetchCostTypeCollection = async () => {
    setCostTypesLoading(true);
    try {
      const response = await getCostTypes(accessToken, fetchCostTypesParams);
      const filteredResponse = response.data.items.filter((x: API.CostType) => x.active)

      setCostTypes(filteredResponse.map((item: API.CostType) => ({
        label: item.description,
        value: item.id,
      })));

    }
    catch (error) {
      console.error('Error', error);
    }
    finally {
      setCostTypesLoading(false);
    }
  }

  //Fetches single project for sent expenses
  const fetchCostType = async (costTypeId: string) => {
    setCostTypesLoading(true);
    try {
      const response = await getCostType(accessToken, costTypeId);
      setCostTypes(prev => [...prev, {
        label: response.data.description,
        value: response.data.id,
        disabled: !response.data.is_active
      }])
    } catch (error) {
      console.error('Error', error);
    }
    finally {
      setCostTypesLoading(false);
    };
  }

  // Memoized debounce handler
  const debouncedFetchCostTypes = useMemo(() => debounce((value) => {
    setFetchCostTypesParams((prev) => ({
      ...prev,
      search: value,
      limit: value === '' ? 10 : 200,  // reset offset on search
    }));
  }, 500), []);

  // Effect to trigger fetch when search parameter changes
  useEffect(() => {
    fetchCostTypeCollection();
  }, [fetchCostTypesParams]);

  /* End of Fetch Cost Types (Expense Accounts) Block */
  const fetchCompanyCardCollection = async () => {
    setCompanyCardsLoading(true);
    try {
      const response = await getCompanyCardCollection(accessToken, fetchCompanyCardsParams)
      const filteredResponse = response.data.items.filter((x: API.CompanyCard) => x.active || x.id === recordToEdit?.company_card_id)
      setCompanyCards(filteredResponse.map((item: API.CompanyCard) => ({
        label: item.card_name,
        value: item.id,
        disabled: response.data.is_active
      })));
    } catch (error) {
      console.error('Error', error);
    } finally {
      setCompanyCardsLoading(false);
    };
  }

  const fetchCompanyCard = async () => {
    if (recordToEdit.company_card_id) {
      setCompanyCardsLoading(true);
      try {
        const response = await getCompanyCard(accessToken, recordToEdit.company_card_id);
        setCompanyCards(prev => [...prev, {
          label: response.data.card_name,
          value: response.data.id,
          disabled: !response.data.active
        }]);
      } catch (error) {
        console.error('Error', error);
      } finally {
        setCompanyCardsLoading(false);
      };
    }
  }
  // Memoized debounce handler
  const debouncedFetchCompanyCards = useMemo(() => debounce((value) => {
    setFetchCompanyCardsParams((prev) => ({
      ...prev,
      search: value,
      limit: value === '' ? 10 : 200,  // reset offset on search
    }));
  }, 500), []);

  // Effect to trigger fetch when search parameter changes
  useEffect(() => {
    fetchCompanyCardCollection();
  }, [fetchCompanyCardsParams]);

  const fetchExpenseHeader = async () => {
    setExpensesLoading(true);
    try {
      const response = await getExpenseHeader(accessToken, recordToEdit.id!);
      const responseExpenseRows = response.data.expense_rows.map((item: ExpenseRowItem) => ({
        id: item.id,
        date: dayjs(item.date),
        title: item.title,
        amount: item.amount,
        expense_account_id: item.expense_account_id,
        file_path: item.file_path,
      }));
      responseExpenseRows.forEach((x: ExpenseRowItem) => {
        if (x.expense_account_id && x.expense_account_id !== '') {
          fetchCostType(x.expense_account_id);
        }
      });

      const filePathList = response.data.expense_rows.map((item: ExpenseRowItem) => ({
        id: item.id,
        fileList: item.file_path ? [{
          uid: item.id,
          name: item.file_path.split('/').pop(),
          url: item.file_path,
          status: 'done',
        }] : undefined
      }));

      setFiles(filePathList);
      form.setFieldValue('expenses', responseExpenseRows);
    }
    catch (error) {
      console.error('Error', error);
    }
    finally {
      setExpensesLoading(false);
    };
  }

  const setInitialForm = () => {
    if (status !== "") {
      form.setFieldValue('is_private_card', recordToEdit.external);
      form.setFieldValue('card', recordToEdit.company_card_id);
      form.setFieldValue('purpose', recordToEdit.purpose);
      form.setFieldValue('date', dayjs(recordToEdit.date));
      form.setFieldValue('dimension', recordToEdit.dimension_id);
      form.setFieldValue('project', recordToEdit.project_id);
    }
    else {
      form.setFieldValue('is_private_card', profile.bank_account);
      form.setFieldValue('date', dayjs());
    }
  }

  useEffect(() => {
    if (formChanged) {
      form.validateFields();
    }
  }, [t])

  useEffect(() => {
    const fetchData = async () => {
      const promises = [
      ];
      if (status !== 'sent') {
        promises.push(fetchDimensionCollection());
        promises.push(fetchProjectCollection());
        promises.push(fetchCompanyCardCollection());
        promises.push(fetchCostTypeCollection());
      }
      else {
        promises.push(fetchDimension());
        promises.push(fetchProject());
        promises.push(fetchCompanyCard());
      }

      if (status !== "") {
        setLoadingExpense(true);
        promises.push(fetchExpenseHeader());
        await Promise.all(promises);
      }
      else {
        Promise.all(promises);
      }
      setLoadingExpense(false);
      setInitialForm();
    };

    fetchData();
  }, []);

  const getTitle = () => {
    switch (status) {
      case 'sent': {
        return t('View expense');
      }
      case 'draft': {
        return t('Edit expense');
      }
      default: {
        return t('Create expense');
      }
    }
  }

  const saveExpenseHeader = () => {
    setApproving(false);
    status === "" ? createExpense() : updateExpense();
  }

  const loadingElement = (
    <Flex align='center' justify='center' style={{ padding: 4 }}>
      <Spin indicator={<SyncOutlined spin />} />
      <span style={{ marginLeft: 8 }}>{t('Loading')}...</span>
    </Flex>
  );

  return (
    <>
      <Card
        title={<PageTitle text={getTitle()} />}
        type="inner"
      >
        <Spin spinning={submitting || loadingExpense}>
          <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            <Form
              form={form}
              onFieldsChange={() => setFormChanged(true)}
              name="control-hooks"
              layout='vertical'
              disabled={status === "sent"}
            >
              <div className='aex-new-expense-form'>
                <Form.Item
                  name="is_private_card"
                  label={t('Purchased with a private card?')}
                  rules={[{ required: true }]}
                  valuePropName='checked'
                >
                  <Switch
                    onChange={onPrivateCardChange}
                    checked={isPrivateCard}
                    disabled={(status === "sent") || (profile.bank_account ? false : true)}
                  />
                </Form.Item>
                <Form.Item
                  name="purpose"
                  label={t('What is purpose of expense?')}
                  getValueFromEvent={(e) => cleanInput(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the field!', { field: t('purpose') }),
                    },
                    () => ({
                      validator(_, value) {
                        return validateInput(value, t);
                      },
                    }),
                  ]}
                >
                  <Input
                    maxLength={255}
                    autoComplete='off'
                  />
                </Form.Item>
                <Form.Item
                  name="date"
                  label={t('Date')}
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the field!', { field: t('date').toLocaleLowerCase() }),
                    }]}
                >
                  <DatePicker
                    minDate={maxMinDates.minDate}
                    maxDate={maxMinDates.maxDate}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="dimension"
                  label={t('Department/Responsible Unit')}
                  rules={[
                    {
                      required: expenseSettings.require_department,
                      message: t('Please enter the field!', { field: t('Department/Responsible Unit').toLocaleLowerCase() }),
                    }]}                    >
                  <Select
                    placeholder={t('Select a department here')}
                    options={dimensions}
                    showSearch
                    onSearch={debouncedFetchDimensions}
                    filterOption={false}
                    dropdownRender={menu => (
                      <div>
                        {dimensionsLoading && loadingElement}
                        {!dimensionsLoading && menu}
                      </div>
                    )}
                  />
                </Form.Item>
                <Form.Item
                  name="project"
                  label={t('Project')}
                  rules={[
                    {
                      required: expenseSettings.require_project,
                      message: t('Please enter the field!', { field: t('project').toLocaleLowerCase() }),
                    }]}
                >
                  <Select
                    placeholder={t('Select a project here')}
                    options={projects}
                    showSearch
                    onSearch={debouncedFetchProjects}
                    filterOption={false}
                    dropdownRender={menu => (
                      <div>
                        {projectsLoading && loadingElement}
                        {!projectsLoading && menu}
                      </div>
                    )}
                  />
                </Form.Item>
                {
                  !isPrivateCard &&
                  <Form.Item
                    name="card"
                    label={t('Company card used')}
                    rules={[
                      {
                        required: true,
                        message: t('Please enter the field!', { field: t('project').toLocaleLowerCase() }),
                      }]}
                  >
                    <Select
                      placeholder={t('Select a card here')}
                      options={companyCards}
                      showSearch
                      onSearch={debouncedFetchCompanyCards}
                      filterOption={false}
                      dropdownRender={menu => (
                        <div>
                          {companyCardsLoading && loadingElement}
                          {!companyCardsLoading && menu}
                        </div>
                      )}
                    />
                  </Form.Item>
                }
              </div>
              <Spin spinning={expensesLoading}>
                <Expenses
                  token={accessToken}
                  status={status}
                  headerId={headerId}
                  form={form}
                  costTypes={costTypes}
                  files={files}
                  onCostTypeSearch={debouncedFetchCostTypes}
                  // fetchCostType={fetchCostType()}
                  costTypesLoading={costTypesLoading}
                  setFiles={setFiles}
                  setFormChanged={setFormChanged}
                  maxMinDates={maxMinDates}
                  approving={approving}
                  multipleRows={files.length > 1 || expenseSettings.multiple_rows}
                />
              </Spin>
            </Form>

            <div style={{ textAlign: 'center' }}>
              <Space>
                <Button type="primary" onClick={saveExpenseHeader} disabled={!formChanged || status === "sent"}>{t('Save')}</Button>
                <Button type="primary" onClick={approveExpense} disabled={status === "" || status === "sent"}>{t('Approve and Submit')}</Button>
              </Space>
            </div>
          </Space>
        </Spin>
      </Card>
    </>

  );
}

export default Expense;