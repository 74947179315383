import React, { useState } from 'react';
import { Card, DatePicker, Flex, Switch, Form, Space, Input, Button, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle/index.tsx';
const { Option } = Select; 

// TO DO : put this page in another folder : Could be a drawer
const SendGifts: React.FC = () => {

      const [form] = Form.useForm();
      const { t } = useTranslation();
      const [isExternalCollection, setIsExternalCollection] = useState(false);
        
      return (
            <>
            <Card
                  title = {<PageTitle text='Submit Documentation for collection/donation' />}
                  type="inner"
            >
                  <Flex wrap="wrap" gap="small" justify='space-between' align = 'start'>
                        <Form
                              form={form}
                              name="control-hooks"
                              onFinish={onFinish}
                              style={{ 
                                    maxWidth: '800',
                              }}
                              layout = 'vertical'
                        >
                              <Form.Item 
                                    name="is_extrenal" 
                                    label={t('External Collection')}
                                    rules={[{ required: true }]}
                              >
                                    <Switch onChange={(checked:boolean) => setIsExternalCollection(checked)} />
                              </Form.Item>

                              <Form.Item 
                                    name="client" 
                                    label={t('Client')} 
                                    rules={[{ required: true }]}
                                    >
                                    <Space style = {{width:'100%'}}>
                                          <Input />
                                          <Button>{t('Change')}</Button>
                                    </Space>
                              </Form.Item>

                              { 
                                    isExternalCollection &&
                                    <Form.Item 
                                          name="collection_for" 
                                          label={t('Collection for')}
                                          rules={[{ required: true }]}
                                          >
                                          <Select
                                                // placeholder="Select a department here" {t('Change')}
                                                allowClear
                                          >
                                                <Option value="Department1">abc</Option>
                                                <Option value="Department2">def</Option>
                                                <Option value="Department3">ghi</Option>
                                          </Select>
                                    </Form.Item>
                              }
                              
                              {/* <Form.Item 
                                    name="purpose" 
                                    label="What is purpose of expense?" {t('Change')}
                                    rules={[{ required: true }]}
                                    >
                                    <Input />
                              </Form.Item> */}

                              <Form.Item 
                                    name="date" 
                                    label={t('Date')}
                                    rules={[{ required: true }]}
                                    >
                                    <DatePicker />
                              </Form.Item>

                              <Form.Item 
                                    name="department" 
                                    label={t('Department/Responsible Unit')}
                                    rules={[{ required: true }]}
                                    >
                                    <Select
                                          // placeholder="Select a department here" {t('Change')}
                                          allowClear
                                    >
                                          <Option value="Department1">Department1</Option>
                                          <Option value="Department2">Department2</Option>
                                          <Option value="Department3">Department3</Option>
                                    </Select>
                              </Form.Item>

                              <Form.Item 
                                    name="project" 
                                    label={t('Project')} 
                                    rules={[{ required: true }]}
                                    >
                                    <Select
                                          // placeholder="Select a project here" {t('Change')}
                                          allowClear
                                    >
                                          <Option value="Project1">Project1</Option>
                                          <Option value="Project2">Project2</Option>
                                          <Option value="Project3">Project3</Option>
                                    </Select>
                              </Form.Item>

                              <Typography.Title level={4}>{t('Amount in NOK')}</Typography.Title>

                              <Form.Item 
                                    name="cash_amount" 
                                    label= {t('Cash Amount')}
                                    rules={[{ required: true }]}
                                    >
                                    <Space>
                                          <Input />
                                          <Button>{t('Add Attachments')}</Button>
                                    </Space>
                              </Form.Item>

                              <Form.Item 
                                    name="vips_amount" 
                                    label= {t('Vipps Amount')}
                                    rules={[{ required: true }]}
                                    >
                                    <Space>
                                          <Input />
                                          <Button>{t('Add Attachments')}</Button>
                                    </Space>
                              </Form.Item>

                              <Form.Item 
                                    name="payment_sum" 
                                    label= {t('Payment terminal sum')}
                                    rules={[{ required: true }]}
                                    >
                                    <Space>
                                          <Input />
                                          <Button>{t('Add Attachments')}</Button>
                                    </Space>
                              </Form.Item>

                              <Form.Item>
                                    <Space>
                                          <Button type="primary" htmlType="submit">
                                                 {t('Save')}
                                          </Button>
                                          <Button htmlType="button">
                                                {t('Approve and Submit')}
                                          </Button>
                                    </Space>
                              </Form.Item>
                        </Form>

                        <Space direction="vertical" >
                              <Card className='info-card' >
                                    <PageTitle text='Submitted by' />
                                    <div>{t('Name')}</div>
                                    <div>{t('Address')}</div>
                                    <div>{t('Postal code / City')}</div>
                              </Card>

                              { 
                                    isExternalCollection &&
                                    <Card className='info-card-second'>
                                          <PageTitle text='Collection for' />
                                          <div>{t('Organization')}{t('Name')}</div>
                                          <div>{t('Organization No.')}</div>
                                          <div>{t('Address')}</div>
                                          <div>{t('Postal code / City')}</div>
                                    </Card>
                              }
                              
                        </Space>                        
                  </Flex>
            </Card>
            </>
            
      );
}
export default SendGifts;