import { useState } from 'react';
import { Button, Form, Modal, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import UserList from 'src/components/UserList/index.tsx';
import { addMember } from 'src/api/api.ts';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';

interface InputProps {
  groupId: string;
  memberType: string;
  onMemberAdded: () => void;
  members: API.GroupMember[];
  buttonText?: string;
  disabled: boolean;
}

export default (props: InputProps) => {
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addingMember, setAddingMember] = useState(false);
  const { t } = useTranslation();

  const handleSelectionChange = (keys: string[]) => {
    setSelectedUsers(keys);
  };

  const handleSubmit = async () => {
    setAddingMember(true);
    let newSelectedUsers = [...selectedUsers];

    for (var idx in selectedUsers) {
      let user = selectedUsers[idx];
      let payload = {
        email: user,
        role: props.memberType.toUpperCase(),
      };
      try {
        await addMember(props.groupId, payload, accessToken);
        props.onMemberAdded();

        newSelectedUsers = newSelectedUsers.filter((id) => id !== idx);

        notification['success']({
          message: t('Success'),
          description: `${user} ${t('has been added successfully.')}`,
          placement: 'topRight',
          duration: 4.5,
        });

        setIsModalOpen(false);
      } catch (err) {
        notification['error']({
          message: t('Error'),
          description: `${t('Failed to add')} ${user} ${t('to')} ${props.groupId}`,
          placement: 'topRight',
          duration: 4.5,
        });
      }
    }
    setSelectedUsers(newSelectedUsers);
    setAddingMember(false);
  };

  const [form] = Form.useForm();

  return (
    <>
      <Button
        type="primary"
        onClick={() => setIsModalOpen(true)}
        disabled={props.disabled}
      >
        <PlusOutlined />
        {' '}{props.buttonText}
      </Button>
      <Modal
        title={t('User List')}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={() => setIsModalOpen(false)}
        centered
        style={{ height: '80vh', maxWidth: '90vw', minWidth: '60vw' }}
        confirmLoading={addingMember}
      >
        <UserList
          handleSelectionChange={handleSelectionChange}
          members={props.members}
          rowSelection={{ enable: true, hideSelectAll: false }}
          selectedUsers={selectedUsers}
        />
      </Modal>
    </>
  );
};
