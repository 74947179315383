import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Checkbox, notification, Space, Select, Modal, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { addCompanyCard, updateCompanyCard } from 'src/api/api.ts';
import UsersList from 'src/components/UsersList';
import { useAppSelector } from 'src/redux/hooks';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  companyCard: API.CompanyCard | null;
  onSubmit: () => void;
}

/**
 * AddNewCompanyCardModal component allows users to add a new company card inside a modal.
 * It includes form fields for card details, users, and admins, and communicates with the API
 * to submit the data.
 * @param companyCard Company card to edit
 * @returns {JSX.Element} React component for adding a new company card in a modal.
 */
const CardModal: React.FC<ModalProps> = ({ open, onClose, companyCard, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [newCardUsers, setNewCardUsers] = useState<string[]>([]);

  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const clientId = useAppSelector((state) => state.client.selectedClientData.id);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (companyCard) {
      form.setFieldsValue({
        card_name: companyCard.card_name,
        account_number: companyCard.account_number,
        active: companyCard.active,
        users: companyCard.users
      });
      setNewCardUsers(companyCard.users);
    }
    else {
      form.resetFields();
    }
  }, [companyCard]);

  useEffect(()=>{
    form.validateFields();
  },[newCardUsers])

  /**
   * Handles form submission to add a new company card.
   *
   * @param {AddCompanyCard} values - The form values for the new company card.
   */
  const handleSubmit = async (values: API.CompanyCardPayload) => {
    setLoading(true);
    try {
      const cardPayload: API.CompanyCardPayload = {
        ...companyCard,
        ...values,
        users: newCardUsers,
        active: values.active ?? false,
        client_id: clientId,
      };
      console.log(values)
      if (companyCard) {
        await updateCompanyCard(accessToken, cardPayload, companyCard.id)
        notification['success']({
          message: t('Success'),
          description: t('Card has been updated successfully!'),
          placement: 'topRight',
          duration: 4.5,
        });
        onSubmit();
        onClose();
      }
      else {
        cardPayload.admins = [];
        await addCompanyCard(accessToken, cardPayload)
        notification['success']({
          message: t('Success'),
          description: t('Card has been added successfully!'),
          placement: 'topRight',
          duration: 4.5,
        });
        onSubmit();
        onClose();
      }
      form.resetFields();
      setNewCardUsers([]);
    }
    catch (error: any) {
      if (error?.response.status === 409) {
        console.error(error);
        const errDetails = error.response.data.detail;
        if (errDetails.msg === 'conflict') {
          form.setFields(errDetails.keys.map((item: any) => ({
            name: item,
            errors: [`${item} already exist so add another value`]
          })))
        }
      } else {
        console.error(error);
        notification['error']({
          message: t('Error'),
          description: t('Sorry, Failed to update company card.'),
          placement: 'topRight',
          duration: 4.5,
        });
      }
    };
    setLoading(false);
  };

  /**
   * Updates the new card users with the selected list.
   *
   * @param {string[]} list - List of selected users.
   */
  const updateNewCardUsers = (list: string[]) => {
    setNewCardUsers((prevNewCardUsers) => {
      const updatedNewCardUsers: string[] = [...prevNewCardUsers];
      for (let item of list) {
        if (!updatedNewCardUsers.includes(item)) {
          updatedNewCardUsers.push(item);
        }
      }
      return updatedNewCardUsers;
    });
  };

  /**
   * Removes a user from the list of new card users.
   *
   * @param {string} email - The email of the user to remove.
   */
  const removeNewcardUser = (email: string) => {
    setNewCardUsers((prevNewCardUsers) => {
      return prevNewCardUsers.filter((item) => item !== email);
    });
  };

  const closeModal = () => {
    onClose();
    form.resetFields();
    setNewCardUsers([]);
  }

  return (
    <>
      <Modal
        open={open}
        title={companyCard ? t('Edit Company Card') : t('Add New Company Card')}
        onCancel={closeModal}
        footer={null}
        destroyOnClose
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            label={t('Card Name')}
            name="card_name"
            required={true}
            rules={[{ required: true }]}
          >
            <Input
              size="middle"
              maxLength={50}
              placeholder={t('Enter card name here')}
            />
          </Form.Item>
          <Form.Item
            label={t('Account Number')}
            name="account_number"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={'0'} // Restrict minimum value to 0
              parser={(value) => value?.replace(/\D/g, '') ?? ''} // Strip non-digit characters
              placeholder={t('Enter account no. here.')}
              maxLength={20}
              controls={false}
              stringMode
            />
          </Form.Item>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox>{t('Is Card Active?')}</Checkbox>
          </Form.Item>
          <Form.Item
            name="users"
            label={t('Users')}
            rules={[
              {
                validator: () => {
                  return newCardUsers && newCardUsers.length > 0
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('At least one user is required')));
                }
              },
            ]}
          >
            <Space direction="vertical">
              <UsersList
                rowSelection={{ enable: true, hideSelectAll: false }}
                selectedUsers={newCardUsers}
                updateSelectedUsers={updateNewCardUsers}
              />
              {newCardUsers.length > 0 && (
                <Select
                  mode="multiple"
                  value={newCardUsers}
                  options={newCardUsers.map((item) => ({ label: item, value: item }))}
                  onDeselect={removeNewcardUser}
                  showSearch={false}
                  open={false}
                />
              )}
            </Space>
          </Form.Item>
          <Space style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('Submit')}
            </Button>
            <Button htmlType="button" onClick={closeModal}>
              {t('Cancel')}
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default CardModal;
