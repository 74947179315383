import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Departments from "./departments";
import Projects from './projects';

const ClientAccess: React.FC = () => {

  const { t } = useTranslation();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Departments'),
      children: <Departments />,
    },
    {
      key: '2',
      label: t('Projects'),
      children: <Projects />,
    },
  ];

  return <Tabs type="card" items={items} />;
}

export default ClientAccess;
