import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Card, Button, Space } from 'antd';
import type { TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import data from '../../mock/collections.json';
import PageTitle from '../../components/PageTitle/index.tsx';

interface DataType {
      key: string;
      title: string;
      date: string;
      sum: string;
      status: string;
}

const Collections: React.FC = () => {

      const navigate = useNavigate();
      const { t } = useTranslation();
      const columns: TableProps<DataType>['columns'] = [
        {
          title: t('Title'),
          dataIndex: 'title',
          key: 'title',
          render: (text, record) => {
              return {
                children: text
              };
            },
        },
        {
          title: t('Date'),
          dataIndex: 'date',
          key: 'date',
          render: (text, record) => {
              return {
                children: text
              };
            },
        },
        {
          title: t('Sum'),
          dataIndex: 'sum',
          key: 'sum',
          render: (text, record) => {
              return {
                children: text
              };
            },
        },
        {
          title: t('Status'),
          key: 'status',
          dataIndex: 'status',
          render: (text, record) => {
              return {
                children: text
              };
            },
        },
        {
          title: t('Action'),
          key: 'action',
          render: (text, record) => {
              return {
                children: record.status === 'draft' ?  <Button type="primary" >{t('Edit')}</Button> : <Button type="primary" >{t('View')}</Button>
              };
            },
        },
  ];

      return (
        <>
          <Card title = {<PageTitle text='Collect and Collection' />} type="inner" >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <div className="bold-notes" >
                {t('Collection Note')}
              </div>
              <Button
                type="primary"
                onClick = {()=>navigate('/send-gift')}
                >
                  {t('Submit Documentation for collection/donation')}
              </Button>
              <Table 
                columns={columns} 
                dataSource={data} 
                scroll={{ x: true }} 
                pagination={{ hideOnSinglePage: true }}
              />
            </Space>
          </Card>
        </>
      );
}
export default Collections;