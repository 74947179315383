import React, { useState } from 'react';
import { Button, Menu, Select } from 'antd';
import { TableOutlined, SettingOutlined, QuestionOutlined, MenuOutlined, UserSwitchOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'src/components/LanguageSwitcher/index.tsx';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { updateSelectedClient, updateSettingClient } from 'src/redux/slices/clientSlice';
import { getClient } from 'src/api/api';
import { logout } from 'src/redux/rootreducer';

const Navigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clientId = useAppSelector(state => state.client.selectedClientData.id);
  const clientOptions = useAppSelector(state => state.client.clientOptions);
  const userEmail = useAppSelector(state => state.user.email);
  const accessToken = useAppSelector(state => state.auth.accessToken);

  const [menuKey, setMenuKey] = useState(0); // Key to force re-render

  const selectClient = async (clientId: string) => {
    try {
      setMenuKey(prevKey => prevKey + 1); // Force menu to re-render and close overflow
      dispatch(updateSettingClient(true));
      const newClientInfo = await getClient(accessToken, clientId);
      dispatch(updateSelectedClient(newClientInfo.data));
      dispatch(updateSettingClient(false));
      navigate('/my-expenses');
    } catch (error: any) {
      console.error(error);
    }
  };

  const logUserOut = () => {
    dispatch(logout());
  };

  const menuItems = [
    accessToken?.length > 0 && clientId !== '' ? {
      label: t('My Expenses'),
      key: '/my-expenses',
      icon: <TableOutlined />,
    } : null,
    accessToken?.length > 0 && clientId !== '' ? {
      label: t('Settings'),
      key: '/settings',
      icon: <SettingOutlined />,
    } : null,
    {
      label: t('Help'),
      key: '/help',
      icon: <QuestionOutlined />,
    },
    accessToken?.length > 0 && clientOptions.length > 1 && clientOptions.length <= 10 && clientId !== '' ? {
      label: (
        <Select
          value={clientId}
          style={{ width: 120 }}
          options={clientOptions}
          onChange={(value) => selectClient(value)}
        />
      ),
      key: 'client',
      disabled: true,
    } : null,
    accessToken?.length > 0 && (clientOptions.length > 9 || clientId === '') ? {
      label: t('Select client'),
      key: 'client',
      icon: <UserSwitchOutlined />
    } : null,
    accessToken?.length <= 0 ? {
      label: t('Sign In'),
      key: 'login',
    } : null,
    {
      label: <LanguageSwitcher />,
      key: 'language',
      disabled: true,
    },
    accessToken?.length > 0 ? {
      label: `${userEmail}`,
      key: 'SubMenu',
      icon: <UserOutlined />,
      children: [
        { label: t('Logout'), key: 'logout', onClick: logUserOut },
      ],
    } : null,
  ].filter(Boolean);

  return (
    <Menu
      key={menuKey} // Use key to force re-render
      overflowedIndicator={<MenuOutlined className="overflow-menu-icon" />}
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={['2']}
      style={{ flex: 1, minWidth: 0 }}
      onClick={({ key }) => {
        if ((accessToken?.length <= 0 && key !== '/help') || key === 'logout') {
          navigate('/login');
        } else {
          navigate(key);
        }
      }}
      items={menuItems}
    />
  );
};

export default Navigation;
