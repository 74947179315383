import React, { useEffect, useState } from "react";
import { Tabs, Button, Table, Popconfirm, notification } from 'antd';
import type { TabsProps, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { getMembers, removeGroupMember } from 'src/api/api';
import getEntitlementGroups from "src/utils/getEntitlementGroups";
import AddMembersIntoGroups from "src/components/AddMembersIntoGroups";
import { useAppSelector } from "src/redux/hooks";
/**
 * Rules to access Client Access Page
 * 1. user has to be Admin or Super Admin
 * 2. Super Admin can read / edit members of users and admins groups
 * 3. Admin can read members of users and admins but can edit only members of users group
 * 4. Client admins should have access to see tenant users so that they can add or remove access or users from client 
 * @returns 
 */

const ClientAccess: React.FC = () => {

  const { t } = useTranslation();

  const accessToken = useAppSelector(state => state.auth.accessToken);
  const userInfo = useAppSelector(state => state.user);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);
  const [loadingAdmins, setLoadingAdmins] = useState(false);
  const [adminMembers, setAdminMembers] = useState<API.GroupMember[]>([]);

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [userMembers, setUserMembers] = useState<API.GroupMember[]>([]);

  const [removingAdmin, setRemovingAdmin] = useState(false);
  const [removingUser, setRemovingUser] = useState(false);

  const client_tenant = `${clientId}.${userInfo.tenant.id}`;
  const adminGroupId = `user.expense.admin@${client_tenant}`;
  const userGroupId = `user.expense.user@${client_tenant}`;

  const { clientAdmin: clientAdminGroups, clientUser: clientUserGroups } = getEntitlementGroups(userInfo.tenant.id, clientId);

  const removeAdminMember = async (memberId: string) => {
    setRemovingAdmin(true);
    try {
      await removeGroupMember(
        adminGroupId,
        memberId,
        accessToken,
      )
      notification['success']({
        message: t('Success'),
        description: `${memberId} ${t('has been removed.')}`,
        placement: 'topRight',
        duration: 4.5,
      });
      fetchAdminMembers();
    } catch (error) {
      console.error(error);
    };
    setRemovingAdmin(false);
  };

  const removeUserMember = async (memberId: string) => {
    setRemovingUser(true);
    try {
      await removeGroupMember(
        userGroupId,
        memberId,
        accessToken,
      )
      notification['success']({
        message: t('Success'),
        description: `${memberId} ${t('has been removed.')}`,
        placement: 'topRight',
        duration: 4.5,
      });
      fetchUserMembers();
    } catch (error) {
      console.error(error);
    };
    setRemovingUser(false);
  };

  const adminColumns: TableProps<API.GroupMember>['columns'] = [
    {
      title: t('Name'),
      dataIndex: 'id',
      key: 'name',
    },
    {
      title: t('Action'),
      key: 'action',
      hidden: !userInfo.permissions.isTenantAdmin,
      render: (value, record) => (
        <>

          <Popconfirm
            title={t("Delete the admin")}
            description={t("Are you sure you want to delete this administrator?")}
            onConfirm={() => removeAdminMember(record.id)}
            okText={t("Yes")}
            okButtonProps={{ loading: removingAdmin }}
            cancelText={t("No")}
          >
            <Button danger>{t('Remove')}</Button>
          </Popconfirm>
        </>
      )
    },
  ];

  const userColumns: TableProps<API.GroupMember>['columns'] = [
    {
      title: t('Name'),
      dataIndex: 'id',
      key: 'name',
    },
    {
      title: t('Action'),
      key: 'action',
      render: (value, record) => (
        <>
          <Popconfirm
            title={t("Delete the user")}
            description={t("Are you sure you want to delete this user?")}
            onConfirm={() => removeUserMember(record.id)}
            okText={t("Yes")}
            okButtonProps={{ loading: removingUser }}
            cancelText={t("No")}
          >
            <Button danger>{t('Remove')}</Button>
          </Popconfirm>
        </>
      )
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Admins'),
      children: <Table
        {...(userInfo.permissions.isTenantAdmin && {
          title: () => (
            <AddMembersIntoGroups
              groupId={clientAdminGroups}
              members={adminMembers}
              disabled={false}
              buttonText={t('Add Admin Members')}
              clientAdminGroup={true}
              fetchMembers={fetchAdminMembers}
            />
          ),
        })}
        columns={adminColumns}
        loading={loadingAdmins}
        dataSource={adminMembers}
        pagination={{
          hideOnSinglePage: true,
        }}
      />,
    },
    {
      key: '2',
      label: t('Users'),
      children: <Table
        title={() => <AddMembersIntoGroups
          groupId={clientUserGroups}
          members={userMembers}
          disabled={false}
          buttonText={t('Add User Members')}
          clientAdminGroup={false}
          fetchMembers={fetchUserMembers}
        />}

        columns={userColumns}
        loading={loadingUsers}
        dataSource={userMembers}
        pagination={{
          hideOnSinglePage: true,
        }}
      />,
    },
  ];

  const filterRootUser = (members:API.GroupMember[]) => {
    return members.filter(x => x.role !== 'OWNER')
  }

  const fetchAdminMembers = async () => {
    setLoadingAdmins(true);
    await getMembers(adminGroupId, accessToken)
      .then((response) => {
        setAdminMembers(filterRootUser(response.data));
      }).catch((error) => {
        console.error(error);
      });
    setLoadingAdmins(false);
  }

  const fetchUserMembers = async () => {
    setLoadingUsers(true);
    await getMembers(userGroupId, accessToken)
      .then((response) => {
        setUserMembers(filterRootUser(response.data));
      }).catch((error) => {
        console.error(error);
      });
    setLoadingUsers(false);
  }

  useEffect(() => {
    fetchAdminMembers();
    fetchUserMembers();
  }, [clientId]);

  return (
    <>
      <Tabs
        type="card"
        items={items}
      />
    </>
  );
}

export default ClientAccess;
