import getEntitlementGroups from "./getEntitlementGroups";

interface permissions {
      isTenantAdmin: boolean;
      isClientAdmin: boolean;
      isClientUser: boolean;
}

const getPermissions = (permissions: string[], clientId: string, tenantId:string):permissions => {
      const {tenantAdmin, clientAdmin, clientUser} = getEntitlementGroups(tenantId, clientId);
     
      const checkGroupMatches = (group:string) => {
            let hasGroup = true;
            if(!permissions.includes(group)) {
                  hasGroup = false;
            }
            return hasGroup;
      }

      return {
            isTenantAdmin: checkGroupMatches(tenantAdmin),
            isClientAdmin: checkGroupMatches(clientAdmin),
            isClientUser: checkGroupMatches(clientUser),
      };
}
export default getPermissions;