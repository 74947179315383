import React, { useEffect, useCallback, useState } from 'react';
import { Button, Card, Form, Radio, Spin, Space, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getClient, getClients, getMembers } from 'src/api/api';
import { useAppSelector, useAppDispatch } from 'src/redux/hooks';
import { updateClientInfo, updateSelectedClient, updateSelectedClientAdmins } from 'src/redux/slices/clientSlice';
import './style.css';
import getPermissions from 'src/utils/getPermissions';
import { updateTenantAdmins } from 'src/redux/slices/tenantSlice';

const Client: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientOptions = useAppSelector(state => state.client.clientOptions);
  const user = useAppSelector(state => state.user);
  const { t } = useTranslation();
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [fetchClientsParams, setFetchClientsParams] = useState<API.FetchParams>({
    limit: 10,
    offset: 0,
  })

  // Fetch clients function wrapped in useCallback to prevent unnecessary re-fetching
  const fetchClients = useCallback(async () => {
    try {
      setLoadingMessage(t('Loading clients'))
      const response = await getClients(accessToken, fetchClientsParams);
      const items = response.data.items || [];
      setTotalItems(response.data.total);
      dispatch(updateClientInfo(items));
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
    finally {
      setLoadingMessage('')
    }
  }, [accessToken, dispatch, fetchClientsParams]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients, fetchClientsParams]);

  const handlePageChange = (page: number, pageSize: number) => {
    setFetchClientsParams((prev) => ({
      ...prev,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    }));
    setCurrentPage(page);
  };

  const selectClient = async (values: { client: string }) => {
    setLoadingMessage(t('Setting client'))
    try {
      // Get client data and save it in redux
      const newClientInfo = await getClient(accessToken, values.client);
      dispatch(updateSelectedClient(newClientInfo.data));
      // Get clientAdmins and save it in redux if client or tenant admin

      const permissions = getPermissions(user.permissions.groups,values.client,user.tenant.id);
      if(permissions.isClientAdmin || permissions.isTenantAdmin){
        const clientAdminGroupId = `user.expense.admin@${newClientInfo.data.id}.${user.tenant.id}`;
        const clientAdmin = await getMembers(clientAdminGroupId, accessToken);
        dispatch(updateSelectedClientAdmins(clientAdmin.data));
        const tenantAdminGroupId = `user.expense.admin@default.${user.tenant.id}`;
        const tenantAdmin = await getMembers(tenantAdminGroupId, accessToken);
        dispatch(updateTenantAdmins(tenantAdmin.data));
      }
      navigate('/my-expenses');
    }
    catch (error: any) {
        console.error(error);
    }
    finally{
      setLoadingMessage('')
    }
  }

  return (
    <Card
      className="aex-client-form"
      title={<span className='aider-exp-title aider-exp-login-title'>{t('Select client')}</span>}
    >
      <Form onFinish={selectClient}>
        <Form.Item
          name="client"
          rules={[{ required: true, message: t('Please select a client') }]}
          className="client-list-container"
        >
          <Spin tip={loadingMessage} spinning={loadingMessage !== ''} style={{ marginTop: '10px' }}>
            <Radio.Group>
              <Space direction="vertical">
                {clientOptions.map(({ label, value }) => (
                  <Radio value={value} key={value}>
                    {label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
            {totalItems > 11 && <Pagination
            align="center"
            current={currentPage}
            onChange={handlePageChange}
            pageSize={fetchClientsParams.limit}
            total={totalItems}
            />}
          </Spin>
        </Form.Item>
        <div className="client-select-btn">
          <Button type="primary" htmlType="submit">
            {t('Select')}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default Client;
