import { DefaultOptionType } from 'antd/es/select';
import { TFunction } from 'i18next';
/**
 * Validates text to match the following rules:
 * 1. Minimum 3 valid characters
 * 2. Valid characters are:
 *  - alphanumeric characters
 *  - numbers
 *  - commas, periods, and hyphens
 *  - norwegian characters ø, æ, and å as well as their uppercase counterparts
 *
 * @param text - Text to validate.
 * @returns a rejected or resolved promise.
 */
export const validateInput = (text: string | undefined, t:TFunction) => {
  if (text === undefined) return Promise.resolve();
  const alphanumericCount = (text.match(/[a-zA-Z0-9åøæÅØÆ]/g) || []).length;
  if (text.length > 0 && alphanumericCount < 3) {
    return Promise.reject(new Error(t('Enter 3 valid characters!')));
  }
  return Promise.resolve();
}

/**
 * Cleans text to match the following rules:
 * 1. Valid characters:
 *  - alphanumeric characters
 *  - numbers
 *  - commas, periods, and hyphens
 *  - norwegian characters ø, æ, and å as well as their uppercase counterparts
 * 2. no double spaces.
 *
 * @param text - String to clean.
 * @returns a cleaned string
 */
export const cleanInput = (text: string) => {
  return text
      .replace(/\s\s+/g, ' ') // Replace double spaces with a single space
      // Allow alphanumeric characters + å, ø, æ (and punctuation like comma, period, hyphen)
      .replace(/[^a-zA-Z0-9åøæÅØÆ,. -]/g, ''); 
}

/**
 * Filters by text on select:
 *
 * @param input - Input string.
 * @param option - option to filter
 * @returns a cleaned string
 */
export const filterOptionsByLabel = (input:string, option:DefaultOptionType | undefined) => {
  return (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase()) // Custom filter to search by label only
}