import { useState, useEffect } from 'react';
import {
  Card,
  Flex,
  Input,
  Space,
  Table,
  TableProps,
  Popconfirm,
  Button,
  notification,
} from 'antd';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getEntitlementGroups, removeGroup } from 'src/api/api.ts';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
/**
 * Component for displaying and managing entitlement groups.
 * @returns JSX element.
 */
export default () => {
  const [loading, setLoading] = useState(false);
  const [groupsData, setGroupsData] = useState<
    API.EntitlementGroup[] | undefined
  >([]);
  const [searchString, setSearchString] = useState<string>('');
  const [pagNum, setPagNum] = useState(0);
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const navigate = useNavigate();
  /**
   * Fetches entitlement group data.
   */
  const fetchGroups = async () => {
    setLoading(true);
    await getEntitlementGroups(accessToken)
      .then((res) => {
        //filter groups of expense based on expense keyword and user"default group
        setGroupsData(res.data.filter((id:string)=> id.includes('expense') || id.includes('users@default')));
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };

  /**
   * Initial fetch
   */
  useEffect(() => {
    fetchGroups();
  }, [accessToken]);

  /**
   * Declaration of columns for antd table.
   */
  const columns: TableProps<API.EntitlementGroup>['columns'] = [
    {
      dataIndex: 'index',
      width: 48,
      key: 'index',
      className: 'tableIndex',
      render: (_, record, index) => [
        <span key={index + 1}>{index + 1 + 10 * pagNum}</span>,
      ],
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('Action'),
      key: 'action',
      render: (_, record) => [
        <Space size="small" key={`middle-${record.id}`} direction="vertical">
          <Button
            key={`see-members-${record.id}`}
            type="link"
            onClick={() => navigate(`/access-control/members/${record.id}`)}
          >
            {t('See Members')}
          </Button>
          <Popconfirm
            title={t('Delete Group')}
            description={t('Are you sure to delete the group?')}
            onConfirm={() => handleRemoveGroup(record.id)}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <Button
              key={`remove-${record.id}`}
              type="text"
              hidden={record.name.startsWith('service')}
              danger
            >
              {t('Remove')}
            </Button>
          </Popconfirm>
        </Space>,
      ],
    },
  ];

  /**
   * Function to remove group from entitlement group data
   * @param currentGroupId
   */
  const handleRemoveGroup = async (currentGroupId: string) => {
    setLoading(true);
    try {
      await removeGroup(currentGroupId, accessToken);
      notification['success']({
        message: t('Success'),
        description: `${currentGroupId} ${t('group has been removed successfully.')}`,
        placement: 'topRight',
        duration: 4.5,
      });
      fetchGroups();
    } catch (error) {
      notification['error']({
        message: t('Error'),
        description: `${t('Failed to remove group')} ${currentGroupId}`,
        placement: 'topRight',
        duration: 4.5,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card>
        <Flex justify={'space-between'} align={'flex-start'}>
          <Space>
            <Input
              placeholder="__________________"
              type="text"
              prefix={<SearchOutlined />}
              onChange={(e) => {
                setSearchString(e.currentTarget.value.toLowerCase());
              }}
            ></Input>
          </Space>
          <Space>
            <RedoOutlined onClick={fetchGroups} />
          </Space>
        </Flex>
        <br></br>
        <Table
          rowKey="id"
          dataSource={groupsData?.filter(
            (item: API.EntitlementGroup) =>
              item.id.indexOf(searchString) > -1 ||
              item.name.indexOf(searchString) > -1
          )}
          columns={columns}
          loading={loading}
          pagination={{
            onChange(page) {
              setPagNum(page - 1);
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
          }}
          bordered
        />
      </Card>
    </>
  );
};
