import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../locales/en-US.json';
import noTranslation from '../locales/nb-NO.json';

const initLanguage = () => {
      i18next
      .use(initReactI18next)
      .init({
            resources: {
                  en: { translation: enTranslation },
                  no: { translation: noTranslation }
            },
            lng: 'no', // default language
            fallbackLng: 'no', // fallback language
            interpolation: {
                  escapeValue: false
            }
      });
}
export default initLanguage;