import React, { useState } from "react";
import { Card, Form, Input, Button, message } from 'antd';
import type { FormProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../api/api.ts';

type FieldType = {
      email?: string;
};

const ForgotPassword: React.FC = () => {

      const { t } = useTranslation();
      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
            setLoading(true);
      
            await resetPassword(values.email!)
                  .then(() => {
                        navigate('/email-sent',{ state: { email:values.email }});
                  })
                  .catch(error => {
                        console.error(error)
                        message.error(t('Forgot password email error'));
                  });
            setLoading(false);
      };

      return (
            <>
                  <Card
                        className="aex-login-form"
                        title={<span className='aider-exp-title aider-exp-login-title'>{t('Forgot Password')}</span>}
                  >
                        <div className="aex-reset-password-text">{t('We will send you reset password link')}</div>
                        <Form
                              name="forgot-password-form"
                              onFinish={onFinish}
                              autoComplete="off"
                        >
                              <Form.Item<FieldType>
                                    label={t("Email")}
                                    name="email"
                                    rules={[{ required: true }, { type: 'email' }]}
                              >
                                    <Input />
                              </Form.Item>
                              <div className="aex-fpwd-button-container">
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                          {t('Reset Password')}
                                    </Button>
                              </div>
                        </Form>
                  </Card>
            </>
      )
}

export default ForgotPassword;