import { useEffect, useState } from 'react';
import { Table, Input, Space, Flex, Tag, Radio, notification } from 'antd';
import type { TableColumnsType } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  RedoOutlined,
  SearchOutlined,
  CheckCircleFilled,
  WarningFilled,
  InfoCircleFilled,
} from '@ant-design/icons';
import { getUsers } from 'src/api/api.ts';
import { TableRowSelection } from 'antd/es/table/interface';
import { useAppSelector } from 'src/redux/hooks';

interface InputProps {
  handleSelectionChange: (keys: any) => void;
  members: API.GroupMember[];
  rowSelection: {
    enable: boolean;
    hideSelectAll: boolean;
  };
  selectedUsers: string[];
}

export default (props: InputProps) => {
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<API.TenantUser[]>([]);
  const [pagNum, setPagNum] = useState(0);
  const [searchString, setSearchString] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [activeFilter, setActiveFilter] = useState<boolean | undefined>(
    undefined
  );

  const loadUsers = async () => {
    setLoading(true);
    try {
      const response = await getUsers(accessToken);
      //props.members contains all the users which are already there in members list
      const filteredUser = response.data.filter((x: any) => 
        props.members.every(y => {
          return y.id !== x.email; // Only keep users where all members' IDs are different
        })
      );
      setUsers(filteredUser);
    } catch (err) {

      notification['error']({
        message: t('Error'),
        description: t('Failed to fetch users'),
        placement: 'topRight',
        duration: 4.5,
      });

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [props.members]);
  /**
   * rowSelection allows user to select rows of a table
   */
  const rowSelection: TableRowSelection<API.TenantUser> = {
    selectedRowKeys,
    hideSelectAll: props.rowSelection.hideSelectAll,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };

  useEffect(()=> {
    if(props.selectedUsers.length === 0 && selectedRowKeys.length > 0)
    setSelectedRowKeys([]);
  },[props.selectedUsers])
  
  const columns: TableColumnsType<API.TenantUser> = [
    {
      title: ' ',
      dataIndex: 'index',
      width: 48,
      key: 'index',
      className: 'tableIndex',
      fixed: 'left',
      render: (_, record, index) => {
        return {
          children: <span key={index + 1}>{index + 1 + 10 * pagNum}</span>,
        };
      },

    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      width: 280,
      ellipsis: true,
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t('First Name'),
      dataIndex: 'first_name',
      key: 'first_name',
      width: 150,
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: t('Last Name'),
      dataIndex: 'last_name',
      key: 'last_name',
      width: 150,
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: t('Joining Date'),
      key: 'date_joined',
      dataIndex: 'date_joined',
      width: 150,
      render: (_, record) => {
        return {
          children: moment(record.date_joined).format('DD-MM-YYYY HH:mm'),
        };
      }
    },
    {
      title: t('Last Login'),
      key: 'last_login',
      dataIndex: 'last_login',
      width: 170,
      render: (_, record) => {
        return {
          children: record.last_login ? moment(record.last_login).format('DD-MM-YYYY HH:mm') : t('None'),
        };
      }
    },
    {
      title: t('Password Age'),
      key: 'password_doc',
      dataIndex: 'password_doc',
      width: 125,
      render: (_, record) => {
        const passwordCreationDate = new Date(record.password_doc);
        const currentDate = new Date();

        // Assuming the password needs to be changed every 90 days
        const passwordExpiryDays = 90;

        const passwordAgeInMillis = currentDate.valueOf() - passwordCreationDate.valueOf();
        const passwordAgeInDays = Math.floor(
          passwordAgeInMillis / (24 * 60 * 60 * 1000)
        );

        let icon = undefined;
        if (passwordAgeInDays < passwordExpiryDays) {
          icon = (
            <CheckCircleFilled style={{ color: 'green', fontSize: '18px' }} />
          );
        } else if (
          passwordAgeInDays >= passwordExpiryDays &&
          passwordAgeInDays <= 365
        ) {
          icon = (
            <WarningFilled style={{ color: 'orange', fontSize: '18px' }} />
          );
        } else {
          icon = (
            <InfoCircleFilled style={{ color: 'red', fontSize: '18px' }} />
          );
        }

        return {
          children: <>
            {icon}
            {` ${passwordAgeInDays} days`}
          </>
        }
      }
    },
    {
      title: t('Login Failures'),
      key: 'throttling_failure_count',
      dataIndex: 'throttling_failure_count',
      width: 120
    },
    {
      title: t('MFA'),
      key: 'device_set',
      dataIndex: 'device_set',
      width: 100,
      render: (_, record) => {
        let tag = undefined;
        if (record.device_set.length === 0) {
          tag = <Tag color="red">{t('Disabled')}</Tag>
        } else if (!record.device_set[0].verified) {
          tag = <Tag color="orange">{t('Not Verified')}</Tag>
        } else {
          tag = <Tag color="green">{t('Enabled')}</Tag>
        }
        return {
          children: tag
        };
      },
    },
    {
      title: t('Email Verification'),
      key: 'emailaddress_set',
      dataIndex: 'emailaddress_set',
      width: 150,
      render: (_, record) => {
        if (record.emailaddress_set.length > 0) {
          const primaryEmail = record?.emailaddress_set?.find(
            (item) => item.email === record.email
          );
          let tag = undefined;
          if (primaryEmail) {
            tag = primaryEmail.verified ? <Tag color="green">{t('Yes')}</Tag> : <Tag color="red">{t('No')}</Tag>
          }
          return {
            children: tag
          };
        }
      },
    },
    {
      title: t('status'),
      key: 'is_active',
      dataIndex: 'is_active',
      width: 120,
      render: (_, record) => {
        return {
          children: record.is_active ? (
            <Tag color="green">{t('Active')}</Tag>
          ) : (
            <Tag color="red">{t('Inactive')}</Tag>
          )
        }
      }
    },
  ];

  useEffect(()=>{
    props.handleSelectionChange(selectedRowKeys);
  },[selectedRowKeys]);

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <>
      <Flex justify={'space-between'} align={'flex-start'}>
        <Space>
          <Input
            placeholder="__________________"
            type="text"
            prefix={<SearchOutlined />}
            onChange={(e) => {
              setSearchString(e.currentTarget.value.toLowerCase());
            }}
          ></Input>
        </Space>
        <Space>
          <Radio.Group
            onChange={(e) => setActiveFilter(e.target.value)}
            value={activeFilter}
          >
            <Radio value={undefined}>
              {t('All')}
            </Radio>
            <Radio value={true}>
              {t('Active')}
            </Radio>
            <Radio value={false}>
              {t('Inactive')}
            </Radio>
          </Radio.Group>
        </Space>
        <Space>
          <RedoOutlined onClick={loadUsers} />
        </Space>
      </Flex>
      {hasSelected && (
        <div>
          <span>
            {hasSelected ? `${t('Selected')} ${selectedRowKeys.length} ${t('users')}` : ''}
          </span>
          <span style={{ float: 'right' }}>
            <a
              type="link"
              onClick={() => setSelectedRowKeys([])}
            >
              {t('Clear')}
            </a>
          </span>
        </div>
      )}

      <Table
        className="aex-table"
        loading={loading}
        {...(props.rowSelection.enable && { rowSelection })}
        columns={columns}
        dataSource={users
          ?.filter(
            (user: API.TenantUser) =>
              user.first_name.indexOf(searchString) > -1 ||
              user.last_name.indexOf(searchString) > -1 ||
              user.email.indexOf(searchString) > -1
          )
          .filter((user: API.TenantUser) =>
            activeFilter !== undefined ? user.is_active === activeFilter : user
          )}
        rowKey={(record) => record.email}
        scroll={{ y: '50vh' }}
        pagination={{
          onChange(page) {
            setPagNum(page - 1);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
        }}
      />
    </>
  );
};
