import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';  // Assuming you're using Redux for state

interface ProtectedRouteProps {
  children: React.ReactNode;
}

/**
 * ProtectedRoute component that checks if the user is authenticated.
 * If not authenticated, it redirects to the login page.
 *
 * @param {React.ReactNode} children - The components to render if the user is authenticated.
 * @returns {React.ReactElement} The rendered children or redirect.
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);
  if (!accessToken) {
    // If there's no access token, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // If there's an access token but not clientId, redirect to client selection 
  if(!clientId && window.location.pathname !== "/client") {
    return <Navigate to="/client" replace />;
  }

  // If authenticated, render the requested page (children)
  return <>{children}</>;
};

export default ProtectedRoute;
