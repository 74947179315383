import { DefaultOptionType } from "antd/es/select";
/**
 * Maps an array of objects to a new format with 'label' and 'value' properties.
 *
 * @param items - The array of objects to be mapped.
 * @param labelKey - The key to be used for the 'label' property in the resulting objects.
 * @param valueKey - The key to be used for the 'value' property in the resulting objects.
 * @param t - The translation function (from useTranslation).
 * @returns A new array of objects with 'label' and 'value' properties as per DefaultOptionType.
 */
export const objectToOptions = (
  items: Array<Record<string, any> | undefined>,
  labelKey: string,
  valueKey: string,
  translator?: (key: string) => string // Translation function
): DefaultOptionType[] => {
  return items
    .filter(item => item !== undefined) // Filter out undefined items
    .map(item => ({
      label: translator ? translator(`option-${item![labelKey]}`) : item![labelKey], // Use translation if required
      value: item![valueKey],
    }));
};