import React, { useEffect, useState } from 'react';
import { Table, Space, Tag, Switch, notification, Flex, Tooltip, Button } from 'antd';
import type { TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUsers, resendVerificationEmail, updateUser } from '../../../api/api.ts';
import moment from 'moment';
import {
  RedoOutlined,
  CheckCircleFilled,
  WarningFilled,
  InfoCircleFilled,
} from '@ant-design/icons';
import AddUser from './addUser.tsx';
import UpdateUser from './updateUser.tsx';
import { themeSettings } from 'src/configs/index.tsx';
import { useAppSelector } from 'src/redux/hooks.ts';

export type UserListMember = API.TenantUser & {
  isClientAdmin: boolean,
  isTenantAdmin: boolean,
}

const UserAdministration: React.FC = () => {

  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientId = useAppSelector(state => state.client.selectedClientData.id);
  const userInfo = useAppSelector(state => state.user);
  const clientAdmins = useAppSelector(state => state.client.selectedClientAdmins);
  const tenantAdmins = useAppSelector(state => state.tenant.tenantAdmins);
  const [usersList, setUsersList] = useState<UserListMember[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [loadingUser, setLoadingUser] = useState<string>('');
  const [sendingEmails, setSendingEmails] = useState<string[]>([]);
  const { t } = useTranslation();

  /**
   * Fetches users
   */
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const result = await getUsers(accessToken);
      // Map the result to include `isClientAdmin`
    const userList = result.data.map((user: API.TenantUser) => ({
      ...user,
      isClientAdmin: clientAdmins.some((admin) => admin.id === user.email),
      isTenantAdmin: tenantAdmins.some((admin) => admin.id === user.email),
    }));

    setUsersList(userList);
    } catch (error: any) {
      console.error(error);
      notification['error']({
        message: t('Error'),
        description: t('Error in fetching user information'),
        placement: 'topRight',
        duration: 4.5,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [accessToken, clientId]);

    /**
   * Handles updating a user's active status and updates the UI state.
   * @param email - Email of the user to update.
   * @param isActive - Desired active status of the user.
   */
  const handleUpdateUser = async (email: string, isActive: boolean) => {
    setLoadingUser(email);
    try {
      const params: API.UpdateUserPayload = {
        tenant: userInfo.tenant.id,
        is_active: isActive,
      };
      await updateUser(accessToken, email, params);
      const msg = `${email} ${t(isActive ? 'is activated.' : 'is inactivated.')}`;
      notification['success']({
        message: t('Success'),
        description: msg,
        placement: 'topRight',
        duration: 4.5,
      });
      setUsersList((prevUsers) =>
        prevUsers.map((user) =>
          user.email === email ? { ...user, is_active: isActive } : user
        )
      );
    } catch (error: any) {
      console.error('Error in handleUpdateUser:', error);
      notification['error']({
        message: t('Error'),
        description: t('Error in updating user'),
        placement: 'topRight',
        duration: 4.5,
      });
    } finally {
      setLoadingUser('');
    }
  };

  /**
   * Handles resending validation email.
   */
  const resendEmail = async (email: string) => {
    setSendingEmails(prev => [...prev, email]);
    try {
      await resendVerificationEmail(email);
      notification['success']({
        message: t('Success'),
        description: `${t('Verification email resent to')} ${email}`,
        placement: 'topRight',
        duration: 4.5,
      });
    } catch (error) {
      notification['error']({
        message: t('Error'),
        description: t('Could not send email'),
        placement: 'topRight',
        duration: 4.5,
      });
    }
    finally {
      setSendingEmails(prev => prev.filter(x => x != email));
    }
  }

  /**
   * column settings and display preferences for antd Table.
   */
  const columns: TableProps<UserListMember>['columns'] = [
    {
      title: t('First Name'),
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: t('Last Name'),
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('Joining Date'),
      key: 'joineddate',
      dataIndex: 'date_joined',
      render: (_, record) => {
        return {
          children: moment(record.date_joined).format('DD-MM-YYYY HH:mm'),
        };
      }
    },
    {
      title: t('Last Login'),
      key: 'last_login',
      dataIndex: 'last_login',
      render: (_, record) => {
        return {
          children: record.last_login ? moment(record.last_login).format('DD-MM-YYYY HH:mm') : t('None'),
        };
      }
    },
    {
      title: t('Password Age'),
      key: 'password_doc',
      dataIndex: 'password_doc',
      render: (_, record) => {
        const passwordCreationDate = new Date(record.password_doc);
        const currentDate = new Date();

        // Assuming the password needs to be changed every 90 days
        const passwordExpiryDays = 90;

        const passwordAgeInMillis = currentDate.valueOf() - passwordCreationDate.valueOf();
        const passwordAgeInDays = Math.floor(
          passwordAgeInMillis / (24 * 60 * 60 * 1000)
        );

        let icon = undefined;
        if (passwordAgeInDays < passwordExpiryDays) {
          icon = (
            <CheckCircleFilled style={{ color: 'green', fontSize: '18px' }} />
          );
        } else if (
          passwordAgeInDays >= passwordExpiryDays &&
          passwordAgeInDays <= 365
        ) {
          icon = (
            <WarningFilled style={{ color: 'orange', fontSize: '18px' }} />
          );
        } else {
          icon = (
            <InfoCircleFilled style={{ color: 'red', fontSize: '18px' }} />
          );
        }

        return {
          children: <>
            {icon}
            {` ${passwordAgeInDays} days`}
          </>
        }
      }
    },
    {
      title: <span style={{ whiteSpace: 'nowrap' }}>{t('Login Failures')}</span>,
      key: 'throttling_failure_count',
      dataIndex: 'throttling_failure_count',
    },
    {
      title: 'MFA',
      key: 'device_set',
      dataIndex: 'device_set',
      render: (_, record) => {
        let tag = undefined;
        if (record.device_set.length === 0) {
          tag = <Tag color="red">{t('Disabled')}</Tag>
        } else if (!record.device_set[0].verified) {
          tag = <Tag color="orange">{t('Not Verified')}</Tag>
        } else {
          tag = <Tag color="green">{t('Enabled')}</Tag>
        }
        return {
          children: tag
        };
      },
    },
    {
      title: t('Email Verification'),
      key: 'emailaddress_set',
      dataIndex: 'emailaddress_set',
      render: (_, record) => {
        if (record.emailaddress_set.length > 0) {
          const primaryEmail = record?.emailaddress_set?.find(
            (item) => item.email === record.email
          );
          let content = undefined;
          if (primaryEmail) {
            if (primaryEmail.verified) {
              content = (<>
                <Tag color="green">{t('Yes')}</Tag>
              </>);
            } else {
              content = (<Space direction='vertical'>
                <Tag color="red">{t('No')}</Tag>
                <Button
                  size='small'
                  type='primary'
                  onClick={() => resendEmail(record.emailaddress_set[0].email)}
                  loading={sendingEmails.includes(record.emailaddress_set[0].email)}>
                  {`${t('Resend verification email')}`}
                </Button>
              </Space>)
            }
          }
          return {
            children: content,
          };
        }
      },
    },
    {
      title: t('Status'),
      key: 'is_active',
      dataIndex: 'is_active',
      render: (_, record) => {
        return {
          children: <Switch
            key={record.email + 'isActive'}
            style={{ backgroundColor: record.is_active ? '#52c41a' : 'orange' }}
            checkedChildren={t('Activated')}
            unCheckedChildren={t('Inactivated')}
            value={record.is_active}
            loading={record.email === loadingUser}
            onChange={() => { if (!(userInfo.permissions.isTenantAdmin || userInfo.permissions.isClientAdmin) || !record.is_root) handleUpdateUser(record.email, !record.is_active) }}
            disabled={
              // User can't deactivate themselves
              userInfo.profile.user_id === record.email ||
              // Root user can't be deactivated
              record.is_root ||
              // Client admin
              (!userInfo.permissions.isTenantAdmin && // Is not tenant admin
                userInfo.permissions.isClientAdmin &&
                (record.isTenantAdmin || // Cannot control TenantAdmins
                  record.isClientAdmin)) || // Cannot control other ClientAdmins
              // Tenant admin
              (!userInfo.isRoot && // Is not root user
                userInfo.permissions.isTenantAdmin &&
                record.isTenantAdmin) // Cannot control TenantAdmins
            }
          />
        };
      }
    },
    {
      title: t('Action'),
      key: 'edit',
      fixed: 'right',
      hidden: !(userInfo.permissions.isTenantAdmin || userInfo.permissions.isClientAdmin),
      render: (_, record) => {
        return {
          children: <UpdateUser
            onUserUpdated={fetchUsers}
            userData = {record}
            disabled={false}
          />
        };
      }
    },
  ];

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Flex justify='space-between'>
          {
            (userInfo.permissions.isTenantAdmin || userInfo.permissions.isClientAdmin) && <AddUser onUserAdded={fetchUsers} clientId={clientId} />
          }

          <Tooltip title={t('Refresh')} color={themeSettings.token.colorPrimary}>
            <RedoOutlined onClick={() => fetchUsers()} />
          </Tooltip>
        </Flex>
        <Table
          rowKey={item => item.email}
          loading={loading}
          columns={columns}
          dataSource={usersList}
          scroll={{ x: true }}
          pagination={{ hideOnSinglePage: true }}
          className="aex-table"
        />
      </Space>
    </>
  );
}
export default UserAdministration;