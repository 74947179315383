import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { tryRefreshingToken } from 'src/utils/tokenUtils';
import store from 'src/redux/store';  // Import the store to access state

const api = axios.create();

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
    const statusCode = error.response?.status;

    switch (statusCode) {
      case 401:
        if (!originalRequest._retry) {
          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers = originalRequest.headers || {};
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                return api(originalRequest);
              })
              .catch((err) => Promise.reject(err));
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise(async (resolve, reject) => {
            try {
              const response = await tryRefreshingToken(store.getState().auth.refreshToken);
              if (response) {
                isRefreshing = false;
                processQueue(null, response.data.access);
                originalRequest.headers = originalRequest.headers || {};
                originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
                resolve(api(originalRequest));
              } else {
                isRefreshing = false;
                processQueue(error, null);
                reject(new Error('Token refresh failed'));
              }
            } catch (err) {
              processQueue(err, null);
              isRefreshing = false;
              reject(err);
            }
          });
        }
        console.error('Unauthorized access - possibly invalid token.');
        break;

      case 403:
        console.error('Forbidden - user does not have necessary permissions.');
        break;

      case 404:
        console.error('Not found - the requested resource does not exist.');
        break;

      case 500:
        console.error('Internal server error.');
        break;

      default:
        console.error(`Error: ${statusCode} - ${error.response?.statusText}`);
    }

    return Promise.reject(error);
  }
);

export default api;
