import React, { useEffect } from 'react';
import { Form, Checkbox, Input, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { addProject, updateProject } from 'src/api/api';
import { useAppSelector } from 'src/redux/hooks';

interface ProjectModalProps {
  open: boolean;
  onClose: () => void;
  project: API.Project | null;
  onSubmit: () => void;
}

const ProjectModal: React.FC<ProjectModalProps> = ({ open, onClose, project, onSubmit }) => {
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const clientData = useAppSelector(state => state.client.selectedClientData);
  const [form] = Form.useForm();
  const isAdd = !project;

  useEffect(() => {
    if (project) {
      form.setFieldsValue({
        description: project.description,
        code: project.code,
        active: project.active,
      });
    }
    else {
      form.resetFields();
    }
  }, [project]);

  const handleFormSubmit = async (values: any) => {
    const data: API.Project = {
      ...project,
      uid: '', // (Sep 12) May be used in the future for external id mapping
      client_id: clientData.id,
      description: values.description,
      code: values.code,
      active: values.active,
      admins: clientData.admins,
      users: clientData.users,
    };
    try {
      if (isAdd) {
        await addProject(accessToken, data);
      } else {
        await updateProject(accessToken, data);
      }
      notification.success({
        message: t('Success'),
        description: `${isAdd ? t('Added') : t('Updated')} ${t('Project')} ${t('successfully')}`,
        placement: 'topRight',
        duration: 4.5,
      });
      onClose();
      onSubmit();
    } catch (error: any) {
      notification.error({
        message: t('Error'),
        description: `${isAdd ? t('Failed to add') : t('Failed to update')} ${t('Project')}, ${t('please try again.')}`,
        placement: 'topRight',
        duration: 4.5,
      });
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();  // Validate the form
      handleFormSubmit(values);  // If valid, submit the form
    } catch (errorInfo) {
    }
  };

  return (
    <Modal
      title={`${isAdd ? t('Add') : t('Edit')} ${t('Project')}`}
      open={open}
      onCancel={onClose}
      onOk={handleOk}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('Description')}
          name="description"
          rules={[{ required: true, message: t('Please enter description') }]}
        >
          <Input maxLength={255} />
        </Form.Item>
        <Form.Item
          label={t('Code')}
          name="code"
          rules={[{ required: true, message: t('Please enter code') }]}
        >
          <Input maxLength={20} />
        </Form.Item>
        <Form.Item name="active" valuePropName="checked" initialValue={true}>
          <Checkbox>{t('Is project active?')}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProjectModal;
