import { useState } from 'react';
import { Popover, Form, Input, FormInstance, Badge, Flex } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useTranslation } from 'react-i18next';
/**
 * Props interface for the component.
 */
interface Props {
  /**
   * antd form instance.
   */
  form: FormInstance<any>;

  /**
   * Placement of the popover (optional).
   */
  popoverPlacement?: string;
}

/**
 * This component adds a layer of validation for a password input and displays the error of validations on value change.
 * It should be used by passing down the form of another component.
 * @example const [myForm] = Form.useForm();
 * <GraspPasswordInput form={myForm}/>
 */
export default (props: Props) => {
  const [passErrors, setPassErrors] = useState<number[]>([]);
  const { t } = useTranslation();

  /** Used for content in password popOver */
  const content = (
    <>
      <Flex vertical>
        <Badge
          status={passErrors.includes(0) ? 'error' : 'success'}
          text={t('At least 8 characters')}
        />
        <Badge
          status={passErrors.includes(1) ? 'error' : 'success'}
          text={t('One lowercase letter')}
        />
        <Badge
          status={passErrors.includes(2) ? 'error' : 'success'}
          text={t('One uppercase letter')}
        />
        <Badge
          status={passErrors.includes(3) ? 'error' : 'success'}
          text={t('One number')}
        />
        <Badge
          status={passErrors.includes(4) ? 'error' : 'success'}
          text={t('One special character')}
        />
        <Badge
          status={passErrors.includes(5) ? 'error' : 'success'}
          text={t('Maximum 25 characters')}
        />
      </Flex>
    </>
  );

  /**
   * Handles password validation.
   * @param {string} value - The form data submitted for registration.
   */
  function passValidator(value: string) {
    const minimumLength = 8;
    const maximumLength = 25;
    const hasLowercase = new RegExp(/^(?=.*[a-z])/);
    const hasUppercase = new RegExp(/^(?=.*[A-Z])/);
    const hasDigit = new RegExp(/^(?=.*\d)/);
    const hasSpecialCharacter = new RegExp(
      /^(?=.*[!#$%&()*+,-./:;<=>?^_`{|}])/
    );

    let errorArr: number[] = [];

    if (value) {
      if (value.length < minimumLength) errorArr.push(0);
      if (value.length > maximumLength) errorArr.push(5);
      if (!hasLowercase.test(value)) errorArr.push(1);
      if (!hasUppercase.test(value)) errorArr.push(2);
      if (!hasDigit.test(value)) errorArr.push(3);
      if (!hasSpecialCharacter.test(value)) errorArr.push(4);
    }
    setPassErrors(errorArr);
    return (
      errorArr.slice(0, -1).join(', ') +
      (errorArr.length > 1 ? ' and ' : '') +
      errorArr.slice(-1)
    );
  }

  return (
    <>
      <Popover
        content={content}
        open={passErrors.length > 0}
        placement={(props.popoverPlacement as TooltipPlacement) ?? 'rightTop'}
        title={`${t('Password requirements')}:`}
      >
        <Form.Item
          name="password"
          label={t('Password')}
          rules={[
            {
              required: true
            },
            () => ({
              validator(_, value) {
                const passValidMsg = passValidator(value);
                if (value === '') {
                  return Promise.reject();
                }
                if (passValidMsg === '') {
                  return Promise.resolve();
                }
                return Promise.reject(new Error());
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password
            className="grasp-input"
            placeholder={t('Please enter your password')}
            onChange={() => props.form.validateFields(['password'])}
          />
        </Form.Item>
      </Popover>
    </>
  );
};
