import React, { useState, useEffect } from 'react';

import { Button, Modal, Form, Input, Tooltip, notification, Checkbox, Spin } from 'antd';
import { updateUser, getMembers, addMember, removeGroupMember, updateProfile, getProfileByUserId, addProfile } from 'src/api/api.ts';
import { EditFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import getEntitlementGroups from 'src/utils/getEntitlementGroups';
import { useAppSelector } from 'src/redux/hooks';
import { UserListMember } from '.';

interface ModalComponentProps {
  onUserUpdated: (email: string, response: UserListMember) => void;
  userData: UserListMember;
  disabled: boolean;
}

interface UpdateUserFormType {
  first_name: string;
  last_name: string;
  isTenantAdmin: boolean;
}

const UpdateUser: React.FC<ModalComponentProps> = ({
  onUserUpdated,
  userData,
  disabled
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [userIsTenantAdmin, setUserIsTenantAdmin] = useState<boolean | undefined>(undefined);
  const [form] = Form.useForm();
  const tenantAdmins = useAppSelector(state => state.tenant.tenantAdmins);
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const tenantId = useAppSelector(state => state.user.tenant.id);
  const isRoot = useAppSelector(state => state.user.isRoot);
  const { t } = useTranslation();
  // Helper function for handling API errors
  const handleApiError = (error: any, form: any) => {
    if (error?.response?.status === 400 && error?.response?.data) {
      const errData = error.response.data;
      if (errData.first_name) {
        form.setFields([{ name: 'first_name', errors: [`${errData.first_name}`] }]);
      }
      if (errData.last_name) {
        form.setFields([{ name: 'last_name', errors: [`${errData.last_name}`] }]);
      }
    } else {
      notification['error']({
        message: t('Error'),
        description: t('Failed to update user information, please try again.'),
        placement: 'topRight',
        duration: 4.5,
      });
    }
  };

  // Fetch or add user profile
  const fetchOrAddUserProfile = async (profile: API.Profile) => {
    try {
      const response = await getProfileByUserId(accessToken, userData.email);
      return response.data;
    } catch (error: any) {
      // Only handle 404, log other errors
      if (error?.response?.status === 404) {
        try {
          const addResponse = await addProfile(accessToken, profile);
          return addResponse.data;
        } catch (addError: any) {
          console.error('Error adding profile:', addError);
          throw addError;
        }
      } else {
        // Log non-404 errors
        console.error('Error fetching profile:', error);
        throw error;
      }
    }
  };

  // Handle group membership changes
  const handleGroupMembership = async (
    isTenantAdmin: boolean,
    tenantAdminGroup: string,
    groupPayload: any,
    accessToken: string
  ) => {
    if (isTenantAdmin) {
      try {
          await addMember(tenantAdminGroup, groupPayload, accessToken);
        }
        catch (error) {
          console.error(`Error adding member to group ${tenantAdminGroup}:`, error);
        }
    } else {
      // Remove from tenant admin groups
      try {
          await removeGroupMember(tenantAdminGroup, groupPayload.email, accessToken);
        } catch (error) {
          console.error(`Error removing member from group ${tenantAdminGroup}:`, error);
        }
    }
  };

  // Main function for handling form submission
  const handleSubmit = async (values: UpdateUserFormType) => {
    setConfirmLoading(true);
    try {
      const userInfoPayload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: userData.email,
        tenant: userData.tenant,
      };

      const response = await updateUser(accessToken, userData.email, userInfoPayload);

      let profilePayload: API.Profile = {
        user_id: userData.email,
        first_name: values.first_name,
        last_name: values.last_name,
        active: true,
      };

      // Fetch or add profile
      const fetchedProfile = await fetchOrAddUserProfile(profilePayload);
      profilePayload = { ...profilePayload, ...fetchedProfile };

      // Update user profile
      await updateProfile(accessToken, profilePayload.id ?? '', profilePayload);

      // Handle tenant admin changes if applicable
      if (values.isTenantAdmin !== userIsTenantAdmin) {
        const { tenantAdmin } = getEntitlementGroups(tenantId);
        const groupPayload = { email: userData.email, role: 'MEMBER' };

        await handleGroupMembership(values.isTenantAdmin, tenantAdmin, groupPayload, accessToken);
      }

      // Success notification
      notification['success']({
        message: t('Success'),
        description: `${userData.email} : ${t('User updated successfully')}`,
        placement: 'topRight',
        duration: 4.5,
      });

      onUserUpdated(userData.email, response.data);
      setIsModalOpen(false);

    } catch (error: any) {
      handleApiError(error, form);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleOk = async () => {
    await form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch();
  };

  const fetchEntitlementsOfUser = async () => {
    try {
      setUserIsTenantAdmin(userData.isTenantAdmin);
      form.setFieldsValue({ isTenantAdmin: userData.isTenantAdmin });
    }
    catch (error) {
      console.error(error);
    };
  }

  useEffect(() => {
    if (isModalOpen) {
      fetchEntitlementsOfUser();
    }
  }, [isModalOpen]);

  return (
    <>
      <Tooltip
        title={
          disabled
            ? t('You are not allowed to edit the user')
            : t('Edit this user')
        }
        color={'#6467c6'}
        key={'edituser'}
      >
        <Button
          type="text"
          onClick={() => setIsModalOpen(true)}
          disabled={disabled}
        >
          <EditFilled />
        </Button>
      </Tooltip>

      <Modal
        title={t('Update Information')}
        open={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => setIsModalOpen(false)}
        confirmLoading={confirmLoading}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={() => form.resetFields()}>
              {t('Reset')}
            </Button>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        {
          userIsTenantAdmin == undefined ?
            <Spin tip={t("Loading user Information")} spinning={true}>
              <div style={{ height: '100px' }}></div>
            </Spin>
            :
            <Form
              form={form}
              initialValues={{
                first_name: userData.first_name,
                last_name: userData.last_name,
                isTenantAdmin: userIsTenantAdmin,
              }}
            >
              <Form.Item<API.UpdateMePayload>
                label={t('First Name')}
                name="first_name"
                rules={[
                  { required: true, message: t('Please enter the field!', {field: t('First Name').toLocaleLowerCase()}) },
                  {
                    pattern: new RegExp(/^[\p{L}øæåØÆÅ_ -]+$/u),
                    message: t('Only letters, spaces, hyphens and underscores are allowed'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Enter first name here.')}
                  maxLength={150}
                />
              </Form.Item>

              <Form.Item<API.UpdateMePayload>
                label={t('Last Name')}
                name="last_name"
                rules={[
                  { required: true, message: t('Please enter the field!', {field: t('Last Name').toLocaleLowerCase()}) },
                  {
                    pattern: new RegExp(/^[\p{L}øæåØÆÅ_ -]+$/u),
                    message: t('Only letters, spaces, hyphens and underscores are allowed'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Enter last name here.')}
                  maxLength={150}
                />
              </Form.Item>
              {isRoot == true && <Form.Item
            name="isTenantAdmin"
            valuePropName="checked"
          >
            <Checkbox>{t('Is tenant admin?')}</Checkbox>
          </Form.Item>}
            </Form>
        }
      </Modal>
    </>
  );
};

export default UpdateUser;
