import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MyExpenses, NewExpense } from 'src/Pages/Expenses/index.tsx';
import Settings from 'src/Pages/Settings';
import Help from 'src/Pages/Help/index.tsx';
import Collections from 'src/Pages/Collections/index.tsx';
import SendGifts from 'src/Pages/Gifts/index.tsx';
import Login from 'src/Pages/Login/index.tsx';
import Client from 'src/Pages/Client/index.tsx';
import ForgotPassword from 'src/Pages/Login/forgetPassword.tsx';
import EmailSent from 'src/Pages/Login/emailSent.tsx';
import MemberManagement from 'src/Pages/Settings/Entitlements/memberManagement.tsx';
import MyInformation from 'src/Pages/Settings/MyInformation/index.tsx';
import UserAdministration from 'src/Pages/Settings/UserAdministration/index.tsx';
import CompanyCardsList from 'src/Pages/Settings/CompanyCards/index.tsx';
import Entitlements from 'src/Pages/Settings/Entitlements/index.tsx';
import ClientAccess from 'src/Pages/Settings/ClientAccess';
import Dimensions from 'src/Pages/Settings/Dimensions/index';
import CostTypes from 'src/Pages/Settings/CostTypes/costTypes';
import ProtectedRoute from 'src/components/ProtectedRoute/ProtectedRoute';

const AppRoutes = () => {
  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/email-sent" element={<EmailSent />} />

        {/* Protected Routes */}
        <Route 
          path="/" 
          element={
            <ProtectedRoute>
              <MyExpenses />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/my-expenses" 
          element={
            <ProtectedRoute>
              <MyExpenses />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/collections" 
          element={
            <ProtectedRoute>
              <Collections />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/help" 
          element={
              <Help />
          } 
        />
        <Route 
          path="/new-expense" 
          element={
            <ProtectedRoute>
              <NewExpense />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/send-gift" 
          element={
            <ProtectedRoute>
              <SendGifts />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/profile" 
          element={
            <ProtectedRoute>
              <div>Profile</div>
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/search" 
          element={
            <ProtectedRoute>
              <div>Search</div>
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/client" 
          element={
            <ProtectedRoute>
              <Client />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/access-control/members/:id" 
          element={
            <ProtectedRoute>
              <MemberManagement />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/settings" 
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="my-information" />} />
          <Route path="my-information" element={<MyInformation />} />
          <Route path="user-administration" element={<UserAdministration />} />
          <Route path="entitlements" element={<Entitlements />}>
            <Route path="members/:id" element={<MemberManagement />} />
          </Route>
            <Route path="company-cards" element={<CompanyCardsList />} />
          <Route path="client-access" element={<ClientAccess />} />
          <Route path="cost-types" element={<CostTypes />} />
          <Route path="dimensions" element={<Dimensions />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
