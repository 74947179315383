const themeSettings = {
      token: {
            // Seed Token
            // colorPrimary: '#0d5a73', // color of menu
            colorPrimary: '#0d5a73', 
            // borderRadius: 2,
    
            // Alias Token
            // colorBgContainer: '#f6ffed',
          },

      components: {
            Menu: {
                    darkItemBg : '#0d5a73',
                    darkItemSelectedBg : '#376b7d',
                    darkPopupBg : '#0d5a73',
            },
            Layout: {
                    headerBg : '#0d5a73'
            },
            Button: {
                  /* here is your component tokens */
            },
      },
};

export default themeSettings;
