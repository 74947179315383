import { useState } from 'react';
import { Button, Flex, Modal, notification } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import UserList from 'src/components/UserList/index.tsx';
import { addMember } from 'src/api/api.ts';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';

interface InputProps {
  groupId: string;
  members: API.GroupMember[];
  buttonText?: string;
  disabled: boolean;
  clientAdminGroup: boolean;
  fetchMembers: () => void;
}

export default (props: InputProps) => {
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addingMember, setAddingMember] = useState(false);
  const { t } = useTranslation();

  const handleSelectionChange = (keys: string[]) => {
    setSelectedUsers(keys);
  };

  const handleSubmit = async () => {
    setAddingMember(true);
    let newSelectedUsers = [...selectedUsers];
    for (const user of selectedUsers) {
      const payload = {
        email: user,
        role: 'MEMBER',
      };

      try {
        // Adding user to all specified groups
        await addMember(props.groupId, payload, accessToken);

        // Filter out the added user from the list of selected users
        newSelectedUsers = newSelectedUsers.filter(id => id !== user);

        // Success notification
        const groupType = props.clientAdminGroup ? 'client admin group' : 'client user group';
        const successMessage = `${user} ${t('has been added to the')} ${groupType}`;
        notification.success({
          message: t('Success'),
          description: successMessage,
          placement: 'topRight',
          duration: 4.5,
        });
        closeModal();
        // Invoke callback after member is added
        props.fetchMembers();

      } catch (err) {
        // Error notification
        const groupType = props.clientAdminGroup ? 'client admin group' : 'client user group';
        notification.error({
          message: t('Error'),
          description: `${t('Failed to add')} ${user} ${t('to')} ${groupType}`,
          placement: 'topRight',
          duration: 4.5,
        });
      }
    }
    setSelectedUsers(newSelectedUsers);
    setAddingMember(false);
  };

  const closeModal = () => {
    setSelectedUsers([]);
    setIsModalOpen(false);
  }

  return (
    <>
    <Flex justify='space-between'>
      <Button
        type="primary"
        onClick={() => setIsModalOpen(true)}
        disabled={props.disabled}
      >
        <PlusOutlined />
        {' '}{props.buttonText}
      </Button>
      <Button onClick={props.fetchMembers}>
      <RedoOutlined />
      </Button>
    </Flex>
      <Modal
        title={t('Tenant User List')}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={closeModal}
        onClose={closeModal}
        centered
        style={{ height: '80vh', maxWidth: '90vw', minWidth: '60vw' }}
        confirmLoading={addingMember}
      >
        <UserList
          handleSelectionChange={handleSelectionChange}
          members={props.members}
          rowSelection={{ enable: true, hideSelectAll: false }}
          selectedUsers={selectedUsers}
        />
      </Modal>
    </>
  );
};
