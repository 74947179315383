import React from "react";
import { Card, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPassword } from '../../api/api.ts';

const EmailSent: React.FC = () => {

      const { t } = useTranslation();
      const navigate = useNavigate();
      const location = useLocation();
      const email = location.state.email;
      const resendLink = async () => {
            await resetPassword(email)
                  .then(() => {
                        message.success(t('Reset link sent successfully to your email'));
                  })
                  .catch((error: any) => {
                        console.error(error);
                        message.error(t('Forgot password email error'));
                  });
      };


      return (
            <>
                  <Card
                        className="aex-login-form"
                        title={<span className='aider-exp-title aider-exp-login-title'>{t('Check your email')}</span>}
                  >
                        <div className="aex-reset-password-text">{t('A link to reset your password has been sent to you on ')} {email}</div>
                        <div className="aex-reset-password-text">{t("If you didn't recieve the email, check your spam folder or")}
                              <Button type="link" onClick={()=>resendLink()}>{t('click here to re-send')}</Button>
                        </div>
                        <div className="aex-fpwd-button-container">
                              <Button type="primary" onClick={()=>navigate('/login')}>
                                    {t('Return to Sign In')} 
                              </Button>
                        </div>
                  </Card>
            </>
      )
}

export default EmailSent;